import React, { Fragment, useState } from "react";
import DeviderLine from "../DeviderLine";
import TechnologyCard from "../TechnologyCard";

import AIMLIMGIcon from "../../assets/common/svgs/Technology/AIML.svg";
import MistralAIIcon from "../../assets/common/svgs/Technology/MistralAI.svg";
import ChatgptIcon from "../../assets/common/svgs/Technology/Chatgpt.svg";
import TensorflowIcon from "../../assets/common/svgs/Technology/Tensorflow.svg";
import OpenCVIcon from "../../assets/common/svgs/Technology/OpenCV.svg";
import HuggingfaceIcon from "../../assets/common/svgs/Technology/Huggingface.svg";
import UnstructuredIcon from "../../assets/common/svgs/Technology/Unstructured.svg";

import MobileIcon from "../../assets/common/svgs/Technology/mobile.svg"
import AndroidIcon from "../../assets/common/svgs/Technology/Android.svg"
import ReactNativeIcon from "../../assets/common/svgs/Technology/React Native.svg"
import iOSIcon from "../../assets/common/svgs/Technology/iOS.svg"
import IonicIcon from "../../assets/common/svgs/Technology/Ionic.svg"
import FlutterIcon from "../../assets/common/svgs/Technology/Flutter.svg"
import PWAIcon from "../../assets/common/svgs/Technology/PWA.svg"


import WebIcon from "../../assets/common/svgs/TechCard.svg";
import ReactIcon from "../../assets/common/svgs/Technology/React.svg"
import AngularIcon from "../../assets/common/svgs/Technology/Angular.svg"
import GatsbyIcon from "../../assets/common/svgs/Technology/Gatsby.svg"
import VueIcon from "../../assets/common/svgs/Technology/Vue.svg"
import NextJsIcon from "../../assets/common/svgs/Technology/Next Js.svg"
import TypescriptIcon from "../../assets/common/svgs/Technology/Typescripts.svg"



import BackendIcon from "../../assets/common/svgs/Technology/backend.svg"
import NodeIcon from "../../assets/common/svgs/Technology/Node.svg"
import PythonIcon from "../../assets/common/svgs/Technology/Python.svg"
import AspDotNetIcon from "../../assets/common/svgs/Technology/ASP.NET.svg"

import PHPIcon from "../../assets/common/svgs/Technology/PHP.svg"
import LaravelIcon from "../../assets/common/svgs/Technology/Laravel.svg"
import CodeIgniterIcon from "../../assets/common/svgs/Technology/CodeIgniter.svg"



import DevOPSIcon from "../../assets/common/svgs/Technology/devops.svg"
import AWSIcon from "../../assets/common/svgs/Technology/AWS.svg"
import GCPIcon from "../../assets/common/svgs/Technology/GCP.svg"
import DockerIcon from "../../assets/common/svgs/Technology/Docker.svg"
import DigitalOceanIcon from "../../assets/common/svgs/Technology/Digital Ocean.svg"
import CICDIcon from "../../assets/common/svgs/Technology/CICD.svg"
import KubernetesIcon from "../../assets/common/svgs/Technology/Kubernetes.svg"



import DatabaseIcon from "../../assets/common/svgs/Technology/database.svg"
import MongoDBIcon from "../../assets/common/svgs/Technology/MongoDB.svg"
import MySQLIcon from "../../assets/common/svgs/Technology/MySQL.svg"
import FirebaseIcon from "../../assets/common/svgs/Technology/Firebase.svg"
import PostgreSQLIcon from "../../assets/common/svgs/Technology/PostgreSQL.svg"
import SQLiteIcon from "../../assets/common/svgs/Technology/SQLite.svg"
import RDSIcon from "../../assets/common/svgs/Technology/RDS.svg"



import CMSIcon from "../../assets/common/svgs/Technology/cms.svg"
import CapacitorIcon from "../../assets/common/svgs/Technology/Capacitor.svg"

import ElectronIcon from "../../assets/common/svgs/Technology/Electron.svg"
import SwiftIcon from "../../assets/common/svgs/Technology/Swift.svg"
import CIcon from "../../assets/common/svgs/Technology/C.svg"
import DotNETIcon from "../../assets/common/svgs/Technology/DotNET.svg"


// import CMSIcon from "../../assets/common/svgs/Technology/cms.svg"
// import WordpressIcon from "../../assets/common/svgs/Technology/Wordpress.svg"
// import ShopifyIcon from "../../assets/common/svgs/Technology/Shopify.svg"
// import WixIcon from "../../assets/common/svgs/Technology/Wix.svg"
// import ButterCMSIcon from "../../assets/common/svgs/Technology/ButterCMS.svg"
// import DrupalIcon from "../../assets/common/svgs/Technology/Drupal.svg"
// import OctoberIcon from "../../assets/common/svgs/Technology/October.svg"




const Technology = () => {

  const [Technolgys, setTechnolgys] = useState([
    {
      name: "AI & ML",
      icon: AIMLIMGIcon,
      tech: [
        {
          icon: MistralAIIcon,
          name: "Mistral AI",
        },
        {
          icon: ChatgptIcon,
          name: "OpenAI",
        },
        {
          icon: TensorflowIcon,
          name: "Tensorflow",
        },
        {
          icon: UnstructuredIcon,
          name: "Unstructured",
        },
        {
          icon: OpenCVIcon,
          name: "OpenCV",
        },
        {
          icon: HuggingfaceIcon,
          name: "Hugging Face",
        },
      ],
    },
    {
      name: "Mobile",
      icon: MobileIcon,
      tech: [
        {
          icon: AndroidIcon,
          name: "Android",
        },
        {
          icon: ReactNativeIcon,
          name: "React Native",
        },
        {
          icon: iOSIcon,
          name: "iOS",
        },
        {
          icon: IonicIcon,
          name: "Ionic",
        },
        {
          icon: FlutterIcon,
          name: "Flutter",
        },
        {
          icon: PWAIcon,
          name: "PWA",
        },
      ],
    },
    {
      name: "Web",
      icon: WebIcon,
      tech: [
        {
          icon: ReactIcon,
          name: "React",
        },
        {
          icon: AngularIcon,
          name: "Angular",
        },
        {
          icon: GatsbyIcon,
          name: "Gatsby",
        },
        {
          icon: VueIcon,
          name: "Vue",
        },
        {
          icon: NextJsIcon,
          name: "Next Js",
        },
        {
          icon: TypescriptIcon,
          name: "Typescript",
        },
      ],
    },
    {
      name: "Backend",
      icon: BackendIcon,
      tech: [
        {
          icon: NodeIcon,
          name: "Node",
        },
        {
          icon: PythonIcon,
          name: "Python",
        },
        {
          icon: AspDotNetIcon,
          name: "ASP.NET",
        },
        {
          icon: PHPIcon,
          name: "php",
        },
        {
          icon: LaravelIcon,
          name: "Laravel",
        },
        {
          icon: CodeIgniterIcon,
          name: "CodeIgniter",
        },
      ],
    },
    {
      name: "DevOps and Cloud",
      icon: DevOPSIcon,
      tech: [
        {
          icon: AWSIcon,
          name: "AWS",
        },
        {
          icon: GCPIcon,
          name: "GCP",
        },
        {
          icon: DockerIcon,
          name: "Docker",
        },
        {
          icon: DigitalOceanIcon,
          name: "Digital Ocean",
        },
        {
          icon: CICDIcon,
          name: "CI-CD",
        },
        {
          icon: KubernetesIcon,
          name: "Kubernetes",
        },
      ],
    },
    // {
    //   name: "Database",
    //   icon: DatabaseIcon,
    //   tech: [
    //     {
    //       icon: MongoDBIcon,
    //       name: "MongoDB",
    //     },
    //     {
    //       icon: MySQLIcon,
    //       name: "MySQL",
    //     },
    //     {
    //       icon: FirebaseIcon,
    //       name: "Firebase",
    //     },
    //     {
    //       icon: PostgreSQLIcon,
    //       name: "PostgreSQL",
    //     },
    //     {
    //       icon: SQLiteIcon,
    //       name: "SQLite",
    //     },
    //     {
    //       icon: RDSIcon,
    //       name: "RDS",
    //     },
    //   ],
    // },
    {
      name: "Desktop",
      icon: CMSIcon,
      tech: [
        {
          icon: CapacitorIcon,
          name: "Capacitor",
        },
        {
          icon: FlutterIcon,
          name: "Flutter",
        },
        {
          icon: ElectronIcon,
          name: "Electron",
        },
        {
          icon: SwiftIcon,
          name: "Swift",
        },
        {
          icon: CIcon,
          name: "C#",
        },
        {
          icon: DotNETIcon,
          name: ".NET",
        },
      ],
    },
  ]);

  return (
    <>
      <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        <DeviderLine title="TECHNOLOGY" right="right-[66.33%]" />
        <div className="mt-[80px] flex justify-between  max_md:mt-[60px] max_md:flex max_md:justify-center">
          {/* <div className="PageContentText w-full max-w-[355px] leading-[24px]  max_md:text-center">
            We pride ourselves on using the latest and most advanced
            technologies to deliver cutting-edge solutions to our clients.
          </div> */}
          {/* <div className="max_md:hidden">
            <ButtonOutliedIcon title="START A PROJECT" />
          </div> */}
        </div>
        <div className="mt-[80px] mb-[120px] flex flex-wrap justify-between max_xl:justify-center max_xl:gap-x-[22px] max_md:mt-[40px] max_md:mb-[100px]">
          {Technolgys.map((itm, index) => (
            <Fragment key={index}>
              <TechnologyCard itm={itm} />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default Technology;
