import React, { Fragment, useState } from "react";
import WEBIMG from "../../assets/common/svgs/web.svg";
import UIUXIMG from "../../assets/common/svgs/ui-ux.svg";
import MOBILEIMG from "../../assets/common/svgs/mobile.svg";
import PRODDEVIMG from "../../assets/common/svgs/prodDev.svg";
import TESTIMG from "../../assets/common/svgs/testing.svg";
import DEVELOPERIMG from "../../assets/common/svgs/developer.svg";
import { useNavigate } from "react-router-dom";

const MenuDropDown = ({ setIsServiceOpen }) => {
  const navigate = useNavigate();
  const [serviceList, setserviceList] = useState([
    {
      Img: WEBIMG,
      name: "Web Development",
      path: "/services-web-development",
    },
    {
      Img: MOBILEIMG,
      name: "Mobile App Development",
      path: "/services-mobile-development",
    },
    {
      Img: UIUXIMG,
      name: "UI/UX Design",
      path: "/services-ui-ux",
    },
    {
      Img: PRODDEVIMG,
      name: "Product Development",
      path: "/services-product-development",
    },
    {
      Img: TESTIMG,
      name: "Quality Analysis",
      path: "/services-testing",
    },
    {
      Img: DEVELOPERIMG,
      name: "Staff Augmentation",
      path: "/services-developer",
    },
  ]);

  return (
    <>
      <div className="grid h-full grid-cols-6 items-center">
        {serviceList.map((itm, index) => (
          <Fragment key={index}>
            <div
              style={{
                position: "relative",
              }}
              onClick={() => {
                navigate(itm.path);
                setIsServiceOpen(false);
              }}
              className="flex cursor-pointer flex-col items-center justify-center "
            >
              <div className="mb-[8px]">
                <img draggable="false"
                  src={itm.Img}
                  className={`${index === 1 ? "w-[19px] h-[25px]" : "w-[25px]"} `}
                  alt="i"
                />
              </div>
              <div className="w-full px-[10px] text-center text-[13px] font-semibold text-blue-mediterranean">
                {itm.name}
              </div>
              {index !== 0 && (
                <div
                  style={{
                    position: "absolute",
                    right: "100%",
                    background: "rgb(72,180,151)",
                    background:
                      "linear-gradient(180deg, rgba(72,180,151,0) 0%, rgba(35,116,161,1) 50%, rgba(72,180,151,0) 100%)",
                  }}
                  className="h-full w-[2px] min-w-[2px] "
                />
              )}
            </div>
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default MenuDropDown;
