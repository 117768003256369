import React, { Fragment } from "react";
import HireTechCard from "./HireTechCard";
import { useNavigate } from "react-router-dom";

const HirePageCard = ({ title, tech }) => {
  return (
    <div className="h-[245px] w-[610px] rounded-[10px] bg-distant-horizon p-[20px] max_xl:h-auto max_xl:w-full max_xl:max-w-[462px] ">
      <div className="flex flex-col items-center justify-center">
        <div className="text-center text-[20px] font-semibold leading-[30px] text-bateau">
          {title}
        </div>
        <div className="mt-[20px] flex flex-wrap justify-center gap-[15px]">
          {tech.map((itm, index) => (
            <Fragment key={index}>
              <HireTechCard title={itm.name} icon={itm.icon} />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HirePageCard;
