import React, { useState, Fragment, useRef, useEffect } from "react";
import Layout from "../utils/Layout";
import HomeBottomBG1 from "../../assets/common/svgs/BottomBG.svg";
import DeviderLine from "../DeviderLine";
import ClientIcon from "../../assets/common/svgs/Hireus/Client.svg";
import TrustedIcon from "../../assets/common/svgs/Hireus/Trusted.svg";
import CollabIcon from "../../assets/common/svgs/Hireus/Collab.svg";
import SolutionCard from "../SolutionCard";
import InputField from "../../Components/utils/InputField";
import BulletIcon from "../../assets/common/svgs/Hireus/BulletPointIcon.svg";
import CImgae from "../../assets/common/svgs/CHire.svg";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import useStateWithValidation from "../../utils/useStateValidation";
import { countryCode } from "../../utils/countryCode";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";

const ClientTechHire = () => {
  const formFocusRef = useRef();
  const location = useLocation();

  const [data] = useState([
    {
      title: "Agile Development",
      icon: ClientIcon,
      desc: "Lorem ipsum dolor sit amet consectetur. Aliquam fermentum tortor mauris purus volutpat tellus. Nec quam varius mauris feugiat adipiscing. Sagittis egestas praesent pellentesque facilisis est. Arcu habitant nunc integer porta. Vel viverra platea amet aliquam mi purus auctor. Massa aliquam lobortis nisl at.",
    },
    {
      title: "Global Reach",
      icon: TrustedIcon,
      desc: "Lorem ipsum dolor sit amet consectetur. Aliquam fermentum tortor mauris purus volutpat tellus. Nec quam varius mauris feugiat adipiscing. Sagittis egestas praesent pellentesque facilisis est. Arcu habitant nunc integer porta. Vel viverra platea amet aliquam mi purus auctor. Massa aliquam lobortis nisl at.",
    },
    {
      title: "Cutting-Edge Technologies",
      icon: CollabIcon,
      desc: "Lorem ipsum dolor sit amet consectetur. Aliquam fermentum tortor mauris purus volutpat tellus. Nec quam varius mauris feugiat adipiscing. Sagittis egestas praesent pellentesque facilisis est. Arcu habitant nunc integer porta. Vel viverra platea amet aliquam mi purus auctor. Massa aliquam lobortis nisl at.",
    },
  ]);

  let subTopics = [
    {
      desc: "Lorem ipsum",
    },
    {
      desc: "Lorem",
    },
    {
      desc: "Lorem",
    },
    {
      desc: "Lorem ipsum",
    },
    {
      desc: "Lorem ipsum dolor",
    },
  ];
  const [isFocused, setIsFocused] = useState(false);

  const [isAllowSubmit, setIsAllowSubmit] = useState(false);
  const [Fname, setFname, isFnameValid, isFnameFoucs] = useStateWithValidation(
    (name) => name !== "",
    ""
  );

  const [email, setEmail, isEmailValid, isEmailFoucs] = useStateWithValidation(
    (email) => {
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return regex.test(email);
    },
    ""
  );

  const [message, setMessage, isMessageValid, isMessageFoucs] =
    useStateWithValidation((message) => true, "");

  const [phone, setPhone, isPhoneValid, isPhoneFoucs] = useStateWithValidation(
    (phone) => phone > 8,
    ""
  );

  const [recaptch, setRecaptch, isRecaptchValid] = useStateWithValidation(
    (recaptch) => recaptch !== "",
    ""
  );

  useEffect(() => {
    if (
      isFnameValid &&
      isEmailValid &&
      isMessageValid &&
      isPhoneValid &&
      isRecaptchValid
    ) {
      setIsAllowSubmit(true);
    } else {
      setIsAllowSubmit(false);
    }
  }, [email, message, phone, recaptch, Fname]);
  const [cCodd, setCCode] = React.useState("+91");

  const handleSubmit = () => {
    if (isAllowSubmit) {
      // console.log("formData", {
      //   Fname,
      //   email,
      //   message,
      //   recaptch,
      //   phone,
      //   countryCode: cCodd,
      // });
    }
  };

  return (
    <Layout>
      {/* HERO */}
      <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
        <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
          <img draggable="false" className="h-full object-cover" src={HomeBottomBG1} alt="" />
        </div>

        <div className="flex flex-col items-center justify-center text-center">
          <div className="HeadingText  mt-[100px] max-w-[788px] max_md:px-[50px] max_sm:px-[20px]">
            Lorem ipsum dolor sit amet consectetur.
          </div>
          <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
            Lorem ipsum dolor sit amet consectetur. Consequat integer faucibus
            suspendisse tempus nisi. In pellentesque ut consectetur in id risus
            auctor eu.
          </div>
        </div>
      </div>

      <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        {/* PART 1 */}
        <div className="mb-[160px] max_md:mb-[100px]">
          <DeviderLine title="HIRE DEVELOPER" right="right-[75%]" />
          <div className="mb-[60px] mt-[90px] flex items-center justify-center max_md:mt-[60px] max_md:flex-col">
            <div className="flex w-full items-center max_md:flex-col-reverse">
              <div className="w-[50%] max_md:mt-[40px] max_md:flex max_md:w-full max_md:flex-col max_md:items-center max_md:justify-center">
                <div className="max-w-[448px] text-center text-[30px] font-bold leading-[45px] text-bateau max_md:text-[24px]">
                  Hire {location?.state?.title || ""} Developer for what you
                  need
                </div>

                <div className="mt-[40px] max-w-[584px] text-[14px] leading-[21px] max_md:mt-[24px] max_md:text-center max_md:text-SmallSize max_md:leading-[18px]">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </div>

                <div className="mt-[30px] grid grid-cols-2 gap-[20px] max_sm:mt-[25px]">
                  {subTopics.map((items, index) => (
                    <div key={index} className="flex items-center gap-[10px]">
                      <div className="flex self-start pt-[3px]">
                        <img draggable="false"
                          className="w-[15px] min-w-[15px] max_md:w-[13px] max_md:min-w-[13px]"
                          src={BulletIcon}
                          alt="Points"
                        />
                      </div>
                      <div className="text-MediumSize leading-[21px] text-[#7D7D7D] max_md:text-[12px] max_md:leading-[18px]">
                        {items.desc}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex w-[50%] justify-center max_md:w-full">
                {/* IMG */}
                <img draggable="false" src={CImgae} alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* PART 2 */}
        <div className="mb-[160px] max_md:mb-[100px]">
          <DeviderLine title="SOLUTION" right="right-[75%]" />
          <div className="mb-[60px] mt-[90px] flex  flex-col items-center justify-center max_md:mt-[60px]">
            <div
              className={`w-full max-w-[444px] text-center text-ExLargeSize font-bold text-duskwood `}
            >
              Turnkey Python development solutions
            </div>
          </div>
          <div className="mt-[80px] flex flex-wrap justify-between gap-[20px] max_md:mb-[30px] max_md:justify-center">
            {data.map((itm, index) => (
              <Fragment key={index}>
                <SolutionCard data={itm} />
              </Fragment>
            ))}
            <div className="flex w-full justify-center">
              <div className="mt-[40px] flex h-[80px] w-[391px] items-center justify-center">
                <div
                  onClick={() => {
                    formFocusRef.current.scrollIntoView({ behavior: "smooth" });
                  }}
                  className="flex h-[60px] w-full cursor-pointer items-center justify-center rounded-[8px] border-[1px] border-bateau"
                >
                  <div className="text-MediumSize font-medium text-duskwood">
                    Hire React Native Developer
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* PART 3*/}
        <div ref={formFocusRef} className="mb-[60px] ">
          <DeviderLine title="SCHEDUALE" right="right-[75%]" />
          <div className="mb-[60px] mt-[90px] flex flex-col items-center justify-center max_md:mt-[60px]">
            <div
              className={`w-full max-w-[672px] text-center text-ExLargeSize font-bold text-duskwood `}
            >
              Start your 15-day trial now and see the benefits for yourself.
            </div>
            <div className="mt-[25px] w-full max-w-[992px] text-center text-RegularSize font-medium text-duskwood max_sm:text-[14px]">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </div>
          </div>
        </div>
        <div className="m-auto flex w-full max-w-[1020px] flex-col justify-center max_md:w-full max_md:max-w-full">
          <InputField
            type="text"
            label="Full Name*"
            placeholder="Full Name"
            name="Name"
            className="relative"
            onChange={(e) => setFname(e.target.value.trim())}
            error={isFnameFoucs && !isFnameValid && "Please enter name"}
          />
          <div className=" mt-[30px] grid h-fit grid-cols-2 gap-x-[20px] gap-y-[30px] max_md:mt-[20px] max_md:grid-cols-1">
            <InputField
              type="email"
              label="Email address*"
              placeholder="Email address"
              name="Email"
              onChange={(e) => setEmail(e.target.value.trim())}
              error={
                isEmailFoucs && !isEmailValid && "Please enter valid email"
              }
            />
            <div className="relative">
              <div className="absolute bottom-[-24px] text-[12px] text-red-400">
                {isPhoneFoucs && !isPhoneValid && "Please enter phone number"}
              </div>
              <label
                className={`text-MediumSize font-medium text-duskwood max_md:text-[14px]`}
              >
                {"Phone Number*"}
              </label>
              <div
                className={`bg-moon-white ${
                  isFocused
                    ? "outline outline-[1px] outline-bateau "
                    : "outline-[0px]"
                } group mt-[9px] flex h-[50px] rounded-[8px]`}
              >
                <Menu placement="bottom-start">
                  <MenuHandler>
                    <Button
                      ripple={false}
                      variant="text"
                      color="blue-gray"
                      className="flex h-full w-[100px] items-center justify-center p-0 pl-[15px] pr-[10px] text-duskwood hover:bg-transparent focus-visible:outline-0 active:bg-transparent"
                    >
                      {cCodd}
                    </Button>
                  </MenuHandler>
                  <div className="h-[35px] w-[1px] min-w-[1px] self-center bg-duskwood"></div>

                  <MenuList className="max-h-[20rem] max-w-[18rem]">
                    {countryCode.map(({ name, code, dial_code }, index) => {
                      return (
                        <Fragment key={index}>
                          <MenuItem
                            key={name}
                            value={name}
                            className="flex items-center gap-2"
                            onClick={() => setCCode(dial_code)}
                          >
                            {name} <span className="ml-auto">{dial_code}</span>
                          </MenuItem>
                        </Fragment>
                      );
                    })}
                  </MenuList>
                </Menu>
                <input
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  type="number"
                  required="required"
                  autoComplete="off"
                  className="focus-visible:none w-full bg-transparent px-[12px] text-[17px] font-medium text-duskwood focus:outline-none focus:outline-[0px] focus:outline-offset-0 focus:outline-bateau max_xl:px-[16px] max_md:text-[14px]"
                  placeholder={"Phone Number"}
                  name={"Phone"}
                  onChange={(e) => setPhone(e.target.value.trim())}
                />
              </div>
            </div>
          </div>
          <div className="mt-[24px]">
            <label
              className={`text-MediumSize font-medium text-duskwood max_md:text-[14px]`}
            >
              {" "}
              Message
            </label>
            <textarea
              type="text"
              name="Message"
              autoComplete="off"
              placeholder="Type Message....."
              className="focus-visible:none mt-[9px] h-[250px] w-full rounded-[8px] bg-moon-white p-[24px] text-[17px] font-medium text-duskwood focus:outline-none focus:outline-[1px] focus:outline-offset-0 focus:outline-bateau max_xl:h-[200px] max_md:text-[14px]"
              onChange={(e) => setMessage(e.target.value.trim())}
            />
          </div>
          <div className="mt-[30px] w-full max_md:mb-[40px]">
            <div className="flex flex-wrap items-center justify-between gap-[20px] ">
              {/* CAPTCHA */}
              <ReCAPTCHA
                sitekey="6LeTjYclAAAAAB9_gSbhJ5wlQwLgecf2qiEKTXE2"
                onChange={(value) => {
                  setRecaptch(value);
                }}
                onExpired={() => {
                  setRecaptch("");
                }}
              />

              {/* BTN */}
              <div
                onClick={() => handleSubmit()}
                className={`flex ${
                  isAllowSubmit
                    ? "cursor-pointer"
                    : "cursor-not-allowed opacity-70"
                } h-[50px] w-[246px] items-center justify-center rounded-[8px] border-[1px] border-none border-expressionism-green bg-gradient-to-r from-expressionism-green to-bateau text-SmallSize font-medium text-white max_md:h-[50px] max_md:w-[200px]`}
              >
                <div className="text-[20px] font-semibold max_md:text-[18px] ">
                  Submit
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ClientTechHire;
