import React, { Fragment, useCallback, useRef, useState } from "react";
import HomeBottomBG1 from "../assets/common/svgs/BottomBG.svg";
import DeviderLine from "../Components/DeviderLine";
import Layout from "../Components/utils/Layout";
import TechnologyCard from "../Components/TechnologyCard";
import Testimonial from "../Components/pageComponent/Testimonial";
import { ReactComponent as ArrIcon } from "../assets/common/svgs/ArrIcon.svg";

import "./Hireus.css";

import MobileIcon from "../assets/common/svgs/Technology/mobile.svg";
import AndroidIcon from "../assets/common/svgs/Technology/Android.svg";
import ReactNativeIcon from "../assets/common/svgs/Technology/React Native.svg";
import iOSIcon from "../assets/common/svgs/Technology/iOS.svg";
import IonicIcon from "../assets/common/svgs/Technology/Ionic.svg";
import FlutterIcon from "../assets/common/svgs/Technology/Flutter.svg";
import PWAIcon from "../assets/common/svgs/Technology/PWA.svg";

import WebIcon from "../assets/common/svgs/TechCard.svg";
import ReactIcon from "../assets/common/svgs/Technology/React.svg";
import AngularIcon from "../assets/common/svgs/Technology/Angular.svg";
import GatsbyIcon from "../assets/common/svgs/Technology/Gatsby.svg";
import VueIcon from "../assets/common/svgs/Technology/Vue.svg";
import NextJsIcon from "../assets/common/svgs/Technology/Next Js.svg";
import TypescriptIcon from "../assets/common/svgs/Technology/Typescripts.svg";

import BackendIcon from "../assets/common/svgs/Technology/backend.svg";
import NodeIcon from "../assets/common/svgs/Technology/Node.svg";
import PythonIcon from "../assets/common/svgs/Technology/Python.svg";
import DotNETIcon from "../assets/common/svgs/Technology/DotNET.svg";
import PHPIcon from "../assets/common/svgs/Technology/PHP.svg";
import LaravelIcon from "../assets/common/svgs/Technology/Laravel.svg";
import MearnStackIcon from "../assets/common/svgs/Technology/Mern-Stack 1.svg";
import FullStackIcon from "../assets/common/svgs/Technology/FullStack.svg";
import MeanStackIcon from "../assets/common/svgs/Technology/MeanStack 1.svg";
import DevOpsIcon from "../assets/common/svgs/Technology/DevOpsdeveloper.svg";
import SoftwareDevIcon from "../assets/common/svgs/Technology/SoftwareDev.svg";
import CodeIgniterIcon from "../assets/common/svgs/Technology/CodeIgniter.svg";
import HirePageCard from "../Components/HirePageCard";

import SolutionCard from "../Components/SolutionCard";

import ClientIcon from "../assets/common/svgs/Hireus/Client.svg";
import TrustedIcon from "../assets/common/svgs/Hireus/Trusted.svg";
import CollabIcon from "../assets/common/svgs/Hireus/Collab.svg";
import EngagementCard from "../Components/EngagementCard";

const HireUs = () => {
  const [Technolgys, setTechnolgys] = useState([
    {
      name: "Mobile",
      icon: MobileIcon,
      tech: [
        {
          icon: AndroidIcon,
          name: "Android",
        },
        {
          icon: ReactIcon,
          name: "React Native",
        },
        {
          icon: iOSIcon,
          name: "iOS",
        },
        {
          icon: IonicIcon,
          name: "Ionic",
        },
        {
          icon: FlutterIcon,
          name: "Flutter",
        },
      ],
    },
    {
      name: "Web",
      icon: WebIcon,
      tech: [
        {
          icon: ReactIcon,
          name: "React",
        },
        {
          icon: AngularIcon,
          name: "Angular",
        },
        {
          icon: GatsbyIcon,
          name: "Gatsby",
        },
        {
          icon: VueIcon,
          name: "Vue",
        },
        {
          icon: NextJsIcon,
          name: "Next Js",
        },
      ],
    },
    {
      name: "Backend",
      icon: BackendIcon,
      tech: [
        {
          icon: NodeIcon,
          name: "Node",
        },
        {
          icon: PythonIcon,
          name: "Python",
        },
        {
          icon: DotNETIcon,
          name: "ASP.Net",
        },
        {
          icon: PHPIcon,
          name: "php",
        },
        {
          icon: LaravelIcon,
          name: "Laravel",
        },
      ],
    },
    {
      name: "Others",
      icon: BackendIcon,
      tech: [
        {
          icon: MearnStackIcon,
          name: "MERN stack",
        },
        {
          icon: FullStackIcon,
          name: "Full stack",
        },
        {
          icon: MeanStackIcon,
          name: "MEAN stack",
        },
        {
          icon: DevOpsIcon,
          name: "Devops",
        },
        {
          icon: SoftwareDevIcon,
          name: "Software",
        },
      ],
    },
  ]);

  const [data] = useState([
    {
      title: "Agile Development",
      icon: ClientIcon,
      desc: "We adopt agile development methodologies to deliver rapid, efficient, and effective solutions that meet your evolving business needs and exceed your expectations. Our team specializes in Scrum development, utilizing a structured framework to promote flexibility, adaptability, and transparency.",
    },
    {
      title: "Global Reach",
      icon: TrustedIcon,
      desc: "With extensive experience delivering IT services across borders, our team is well-equipped to navigate complex regulatory and compliance requirements, ensuring your business remains compliant and secure.",
    },
    {
      title: "Cutting-Edge Technologies",
      icon: CollabIcon,
      desc: "We are passionate about adopting and innovating with cutting-edge technologies to deliver top-quality IT solutions that empower our client’s success. We leverage the latest tools and techniques to develop bespoke solutions.",
    },
  ]);

  const [engaeData, setEngaeData] = useState([
    {
      title: "Fixed Pricing",
      description:
        "The fixed price model is suitable for clients who have a well-defined scope of work and clear project requirements, as it allows them to have a clear understanding of the total cost of the project upfront. The model provides predictability and stability to the client, and helps them to manage their budget and resources more effectively.",
      subTopics: [
        {
          desc: "Ideal for projects with clear requirements.",
        },
        {
          desc: "Budget predictability for clients.",
        },
        {
          desc: "Visualize your final product without spending on development.",
        },
      ],
    },
    {
      title: "Pay as you go",
      description:
        "Pay as you go is a pricing model that charges clients on an hourly basis for the work done by the IT service provider. In this model, the client and IT service provider agree on an hourly rate, and the client is charged for the actual hours worked by the provider on the project.",
      subTopics: [
        {
          desc: "Pay per hour spent by each department on your project.",
        },
        {
          desc: "Ideal for projects with constantly increasing requirements.",
        },
        {
          desc: "Maintain & support your existing product.",
        },
      ],
    },
    ,
  ]);

  return (
    <Layout>
      {/* HERO SECTION */}
      <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
        <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
          <img draggable="false" className="h-full object-cover" src={HomeBottomBG1} alt="" />
        </div>

        <div className="flex flex-col items-center justify-center text-center">
          <div className="HeadingText  mt-[100px] max-w-[788px] max_md:px-[50px] max_sm:px-[20px]">
            We will help you get your website done
          </div>
          <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
            iGeek experiences. So you can build relationships & Increasing your
            company's value. Creating a brand identity is not a dream anymore.
            We will get Your on top.
          </div>
        </div>
      </div>

      <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        {/* SOLUTION */}
        <DeviderLine title="SOLUTION" right="right-[25%]" />
        <div className="my-[60px] flex flex-col items-center justify-center">
          <div
            className={`w-full max-w-[443px] text-center text-ExLargeSize font-bold text-duskwood `}
          >
            Solutions for Your Business Challenges
          </div>
        </div>
        <div className="mb-[90px]">
          <div className="mb-[160px] mt-[80px] flex flex-wrap justify-between gap-[20px] max_md:mb-[30px] max_md:justify-center">
            {data.map((itm, index) => (
              <Fragment key={index}>
                <SolutionCard data={itm} />
              </Fragment>
            ))}
          </div>
        </div>

        {/* WORK */}
        <div className="my-[160px] max_md:mb-[30px]">
          <DeviderLine title="HIRE" right="right-[50%]" />

          <div className="my-[60px] flex flex-col items-center justify-center">
            <div
              className={`w-full max-w-[243px] text-center text-ExLargeSize font-bold text-duskwood `}
            >
              Hire Developer
            </div>
          </div>

          <div className="flex h-full w-full flex-wrap items-center justify-between gap-[20px] max_xl:gap-[16px] max_md:justify-center">
            {Technolgys.map((itm, index) => (
              <Fragment key={index}>
                <HirePageCard title={itm.name} tech={itm.tech} />
              </Fragment>
            ))}
          </div>
        </div>

        {/* ENGAGEMENT */}
        <div className="pb-[20px]">
          <DeviderLine title="ENGAGEMENT" right="right-[75%]" />

          <div className="my-[66px] flex flex-col items-center justify-center">
            <div
              className={`w-full max-w-[364px] text-center text-ExLargeSize font-bold text-duskwood `}
            >
              Client Engagement Frameworks
            </div>
          </div>

          <div className="flex flex-wrap justify-center gap-[20px]">
            {engaeData.map((itm, index) => (
              <EngagementCard
                title={itm.title}
                description={itm.description}
                subTopics={itm.subTopics}
              />
            ))}
            {/* <EngagementCard /> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HireUs;
