import React, { useState, Fragment } from "react";
import HomeBottomBG1 from "../assets/common/svgs/BottomBG.svg";
import DeviderLine from "../Components/DeviderLine";
import Layout from "../Components/utils/Layout";
import { ButtonOutliedIcon } from "../Components/utils/Buttons";
import ProjectCount from "../Components/utils/ProjectCount";
import BG1 from "../assets/common/images/CaseStudy/Rectangle.png";
import BG2 from "../assets/common/images/CaseStudy/Circle.png";
import { AllProjects } from "../technology/data";

const CaseStudy = () => {
  const [isAllProjects, setIsAllProjects] = useState(false);
  const [Projects, setProjects] = useState(AllProjects.slice(0, 5));
  const [filterButton, setFilterButton] = useState([
    { title: "All", active: true },
    { title: "Website", active: false },
    { title: "Mobile Application", active: false },
    { title: "Extensions", active: false },
  ]);

  const handleSeeAll = () => {
    setIsAllProjects(true);
    setProjects(AllProjects);
  };

  const filterProjects = (index) => {
    setIsAllProjects(true);
    if (filterButton[index].title === "All") {
      setProjects(AllProjects);
    } else {
      setProjects(
        AllProjects.filter(
          (project) => project.filter === filterButton[index].title
        )
      );
    }
    setFilterButton((prevFilterButton) => {
      const updatedFilterButton = prevFilterButton.map((button, i) => ({
        ...button,
        active: i === index,
      }));
      return updatedFilterButton;
    });
  };

  return (
    <>
      <Layout>
        {/* HERO SECTION */}
        <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
          <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
            <img
              draggable="false"
              className="h-full object-cover"
              src={HomeBottomBG1}
              alt=""
            />
          </div>

          <div className="relative flex flex-col items-center justify-center text-center">
            <div className="HeadingText mt-[100px] max-w-[725px] max_md:px-[50px] max_sm:px-[20px]">
              Real world examples of our innovative IT solutions
            </div>
            <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
              Our projects are a reflection of our commitment to delivering
              innovative IT solutions that help businesses achieve their goals
              and dreams.
            </div>
            <div className="absolute bottom-[-25%] left-[-150px] w-fit max_xl:left-[-30px] max_md:bottom-[-36%] max_md:left-[20%] max_sm:left-[10%]">
              <ProjectCount count="200+" title="Project Completed" />
            </div>
            <div className="absolute bottom-[-40%] left-[220px] w-fit max_xl:left-[250px] max_md:bottom-[-36%] max_md:left-[60%]">
              <ProjectCount count="100+" title="Client Served" />
            </div>
            <div className="absolute bottom-[-40%] right-[220px] w-fit max_xl:right-[250px] max_md:bottom-[-80%] max_md:left-[24%] max_sm:bottom-[-70%] max_sm:left-[14%]">
              <ProjectCount count="40+" title="Professionals" />
            </div>
            <div className="absolute bottom-[-25%] right-[-140px] w-fit max_xl:right-[-30px] max_md:bottom-[-80%] max_md:left-[57%] max_sm:bottom-[-70%]">
              <ProjectCount count="5+" title="Years Of Experience" />
            </div>
          </div>
        </div>
        <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
          <div className="mb-[40px]">
            <DeviderLine title="PROJECT" right="right-[75%]" />
          </div>

          <div className="mb-[120px] flex justify-center gap-6 max_720:flex-wrap">
            {filterButton.map((value, index) => {
              return (
                <div
                  key={index}
                  className={`flex h-[50px] w-[25%] cursor-pointer items-center justify-center rounded-[5px] text-MediumSize font-medium max_md:text-SmallSize max_720:min-w-[150px] ${
                    value.active
                      ? "bg-gradient-to-r from-bateau to-expressionism-green text-white"
                      : "bg-light-gray  text-black"
                  }`}
                  onClick={() => filterProjects(index)}
                >
                  <div>{value.title}</div>
                </div>
              );
            })}
          </div>
          <div>
            {Projects.map((itm, index) => (
              <Fragment key={index}>
                <div
                  className={`mt-[200px] max_md:mt-[100px] ${
                    index % 2 === 0 ? "flex-row" : "flex-row-reverse"
                  } flex w-full max_md:flex-col ${
                    index === Projects.length - 1 &&
                    "mb-[140px] max_md:mb-[70px]"
                  }`}
                >
                  <div className="relative flex w-[50%] max-w-[1920px] items-center justify-center max_md:w-full">
                    <img draggable="false" src={itm.IMG} alt="" />
                    {index % 2 === 0 && (
                      <div className="absolute bottom-[-130px] left-[-200px] z-[-1] w-full max_xl:w-[500px] max_sm:h-[450px] max_sm:w-[400px]">
                        <img
                          draggable="false"
                          className="h-full object-cover object-right"
                          src={BG1}
                          alt=""
                        />
                      </div>
                    )}
                    {index % 2 !== 0 && (
                      <div className="absolute bottom-[-120px] right-[-180px] z-[-1]  max_xl:w-[420px] max_sm:h-[450px] max_sm:w-[340px]">
                        <img
                          draggable="false"
                          className="h-full object-cover object-left	"
                          src={BG2}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`flex w-[50%] flex-col justify-center max_md:w-full max_md:pl-[0px]  ${
                      index % 2 === 0 ? "pl-[70px]" : "pl-[0px]"
                    } `}
                  >
                    <div className="max_  text-[46px] font-semibold text-bateau max_xl:text-[40px]">
                      {itm.title}
                    </div>
                    <div className="mb-[25px] mt-[19px] max-w-[488px] text-MediumSize font-medium leading-[24px] max_xl:mt-[14px] max_md:text-SmallSize">
                      {itm.description}
                    </div>
                    <div className="mb-[14px] max-w-[488px] text-MediumSize font-medium leading-[24px] text-bateau max_md:text-SmallSize">
                      Technology
                    </div>
                    <div className="mb-[25px] grid max-w-[488px] grid-cols-3 gap-[10px] ">
                      {itm.technologies.map((tech, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-[10px] text-[14px] leading-[21px] text-black-oak max_md:text-[12px] max_md:leading-[18px]"
                        >
                          <span className=" h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
                          {tech}
                        </div>
                      ))}
                    </div>
                    <div className="w-fit">
                      {itm.link !== "" ? (
                        <a
                          href={`${itm.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ButtonOutliedIcon title="View" />
                        </a>
                      ) : (
                        <ButtonOutliedIcon title="View" />
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </div>

        {!isAllProjects && (
          <div className="flex w-full justify-center">
            <div className="flex h-[80px] w-[230px] items-center justify-center">
              <div
                onClick={handleSeeAll}
                className="flex h-[62px] w-full cursor-pointer items-center justify-center rounded-[8px] border-[1px] border-[#B4E5FF] shadow-[0px_4px_10px_rgba(0,0,0,0.1)]"
              >
                <div className="text-MediumSize font-medium text-duskwood">
                  Load More Project
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default CaseStudy;
