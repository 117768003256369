import React, { Fragment } from "react";
import LinesSVG from "../../assets/common/svgs/whyIGeekBG.svg";
import WhiteIGeekSVG from "../../assets/common/svgs/whiteIGeek.svg";
import IGeekCard from "../IGeekCard";
import AgileSVG from "../../assets/common/svgs/whyiGeek/agile.svg";
import GlobalSVG from "../../assets/common/svgs/whyiGeek/global.svg";
import CuttingSVG from "../../assets/common/svgs/whyiGeek/curting.svg";
import TrackSVG from "../../assets/common/svgs/whyiGeek/track.svg";
import { useState } from "react";


const WhyIGeek = () => {
  const [data] = useState([
    {
      title: "Agile Development",
      icon: AgileSVG,
      desc: "Our Agile development approach allows us to deliver high-quality software solutions that are tailored to our clients' unique needs and objectives. We ensure that we provide flexible, adaptive, and customer-centric solutions.",
    },
    {
      title: "Flexible Engagement Model",
      icon: GlobalSVG,
      desc: "Giving cost-effective services to clients is our priority and accordingly we offer clients flexible engagement models. Our engagement models are designed to provide our clients with greater flexibility, control, and transparency.",
    },
    {
      title: "Cutting-Edge Technologies",
      icon: CuttingSVG,
      desc: "We specialize in using latest technologies for mobile and web development services for making scalable product. We work closely with our clients to understand their specific needs and objectives, and we tailor our solutions accordingly.",
    },
    {
      title: "Transparency & Commitment",
      icon: TrackSVG,
      desc: "We are committed to providing clear and accurate information about our work, project updates, timelines, and pricing and dedicated to delivering high-quality work that meets or exceeds our client's expectations.",
    },
  ]);

  return (
    <>
      <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        {/* <DeviderLine title="PROCESS" right="right-[50%]" /> */}
        <div className="flex flex-col items-center justify-center">
          <div className="PageTitle mt-[0px] mb-[16px]">Why Choose iGeek ?</div>
          <div className="PageContent mb-[80px] w-full max-w-[745px] text-center">
            At iGeek, we take pride in providing top-notch IT services and
            solutions that help our clients achieve their goals. We are
            dedicated to staying ahead of the curve when it comes to the latest
            technological advancements and strive to deliver innovative and
            effective solutions that meet the unique needs of each of our
            clients.
          </div>
        </div>

        <div className="flex flex-col items-center justify-center max_md:mb-[30px]">
          <div className="cursor-pointer text-SmallSize font-medium text-black">
            <div
              style={{
                background:
                  "linear-gradient(45deg,rgba(34,117,161,1) 0%, rgba(79,189,150,1) 70%)",
              }}
              className={`flex h-[60px] w-[230px] items-center justify-center rounded-[6px] bg-gradient-to-r from-expressionism-green to-bateau text-white`}
            >
              <div className="text-[11px] font-semibold ">
                <img draggable="false" src={WhiteIGeekSVG} alt="" />
              </div>
            </div>
          </div>
          <div className="max_xl:w-[725px] max_md:hidden max_md:w-[470px]">
            <img draggable="false" src={LinesSVG} alt="" />
          </div>
        </div>

        <div className="flex flex-wrap justify-between max_md:m-auto max_md:w-[470px] max_md:justify-center max_md:gap-y-[20px] max_sm:w-[300px] max_sm:justify-center">
          {data.map((itm, index) => (
            <Fragment key={index}>
              <IGeekCard data={itm} />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default WhyIGeek;
