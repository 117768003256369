import React from "react";
import { useNavigate } from "react-router-dom";

const HireCard = ({ title = "title", img = "" }) => {
  const navigate = useNavigate();
  return (
    <div
      // style={{
      //   boxShadow: "0px 3.04668px 15.2334px rgba(0, 0, 0, 0.1)",
      // }}
      onClick={() => {
        navigate("/contact-us");
        // navigate("/hire-us");
      }}
      className={`flex h-[303px] w-[400px] cursor-pointer flex-col items-center justify-between rounded-[10px] border-[1px] border-[#4fbd9659] hover:border-transparent  hover:shadow-[0px_2px_16px_rgba(0,0,0,0.1)]`}
    >
      <div className="HireCardHeading mt-[35px]">{title}</div>
      <div className="mb-[30px]">
        <img draggable="false" src={img} alt="" />
      </div>
    </div>
  );
};

export default HireCard;
