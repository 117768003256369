import React from "react";

const SolutionCard = ({ data }) => {
  return (
    <div className="flex h-[400px] w-[400px] cursor-pointer flex-col items-center rounded-[18px] border-[1px] border-expressionism-green p-[32px]  hover:border-transparent hover:shadow-[0px_2px_16px_rgba(0,0,0,0.1)] max_xl:h-[300px] max_xl:w-[300px] max_xl:p-[25px] max_xl:pt-[15px]">
      <div className="flex h-[100px] w-[100px] items-center justify-center rounded-full bg-[#F2FAF8] max_xl:h-[60px] max_xl:min-h-[60px] max_xl:w-[60px] max_xl:min-w-[60px]">
        <img draggable="false" className="max_xl:w-[28px]" src={data.icon} alt="" />
      </div>
      <div className="mt-[20px] text-center text-LargeSize font-semibold leading-[27px] text-bateau max_xl:mt-[10px] max_xl:text-[16px] max_xl:leading-[24px]">
        {data.title}
      </div>
      <div className="mt-[20px] text-center text-[14px] leading-[21px] tracking-[1px] text-black-oak max_xl:mt-[10px] max_xl:text-[12px] max_xl:leading-[17px] max_xl:tracking-[0.5px]">
        {data.desc}
      </div>
    </div>
  );
};

export default SolutionCard;
