import React, { useState, Fragment } from "react";
import HomeBottomBG1 from "../../assets/common/images/HomeBG2.png";
import ServiceSectionComp from "../../Components/ServiceSectionComp";
import Layout from "../../Components/utils/Layout";
import ProdDevelopIMG from "../../assets/common/svgs/service/ProdDevelopIMG.svg";
import ToolIMG from "../../assets/common/images/ToolImg.png";
import Testimonial from "../../Components/pageComponent/Testimonial";
import ServiceCard from "../../Components/ServiceCard";
import DeviderLine from "../../Components/DeviderLine";

const ServiceProdDevelop = () => {
  const [serviceData, setserviceData] = useState({
    DeviderTitle: "WHY IGEEK",
    Heading: "Why choose iGeek for Product Development services?",
    Image: ProdDevelopIMG,
    description:
      "From ideation to deployment, we can guide you through the entire software development lifecycle. Our expertise in various technologies and development methodologies enables us to deliver innovative solutions on time and within budget. Whether you need mobile apps, web applications, or enterprise software, we can help you bring your ideas to life.",
    title: "Our Product Development services",

    cards: [
      {
        Image: ToolIMG,
        title: "Product Engineering",
        background: "bg-placebo-turquoise",
        description:
          "Our experienced product designers and engineers work closely with you to understand your requirements and create innovative designs that cater to your target audience.",
      },
      {
        Image: ToolIMG,
        title: "MVP Development",
        background: "bg-clear-skies",
        description:
          "Our agile and cost-effective approach to MVP development allows you to test, validate, and refine your product quickly, ensuring that you invest your time and resources wisely.",
      },
      {
        Image: ToolIMG,
        title: "Enterprise Applications",
        background: "bg-placebo-turquoise",
        description:
          "We custom-built, high-performance applications are designed to empower your organization and drive innovation enabling businesses to streamline operations.",
      },
      {
        Image: ToolIMG,
        title: "Product Lifecycle management",
        background: "bg-clear-skies",
        description:
          "We provide custom solutions for lifecycle management that focuses on marketing, branding and regular testing with maintenance and support.",
      },
      {
        Image: ToolIMG,
        title: "Seamless Integrations",
        background: "bg-placebo-turquoise",
        description:
          "Our software product developers design softwares that can integrate seamlessly with your existing systems, minimizing disruptions and making implementation a breeze.",
      },
    ],
  });

  const [otherService, setotherService] = useState([
    {
      Image: ToolIMG,
      title: "Web Development",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-web-development",
    },
    {
      Image: ToolIMG,
      title: "UI/UX Design",
      background: "bg-clear-skies",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-ui-ux",
    },
    {
      Image: ToolIMG,
      title: "QA/Testing",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-testing",
    },
    {
      Image: ToolIMG,
      title: "Dedicated Developer/Team",
      background: "bg-clear-skies",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-developer",
    },
    {
      Image: ToolIMG,
      title: "Mobile app Development",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-mobile-development",
    },
  ]);

  return (
    <>
      <Layout>
        <div className="">
          <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
            <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
              <img draggable="false" className="h-full object-cover" src={HomeBottomBG1} alt="" />
            </div>

            <div className="flex flex-col items-center justify-center text-center">
              <div className="HeadingText mt-[100px] max-w-[886px] max_md:px-[50px] max_sm:px-[20px]">
                Build Best Product Development Service With iGeek
              </div>
              <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
                iGeek experiences. So you can build relationships & Increasing
                your company's value. Creating a brand identity is not a dream
                anymore. We will get Your on top.
              </div>
            </div>
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <ServiceSectionComp
              maxWidth={"max-w-[396px]"}
              serviceData={serviceData}
            />
          </div>

          {/* <div className="mt-[160px] max_md:mt-[80px]">
            <Testimonial />
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <div className="mt-[160px] max_md:mt-[80px]">
              <DeviderLine title="OTHERS" right="right-[75%]" />

              <div className="mt-[65px] flex flex-wrap justify-center gap-[15px] max_md:mt-[40px]">
                <div className="w-[403px] pt-[40px] text-[30px] font-bold leading-[45px] text-duskwood max_md:pt-[0px] max_md:pb-[40px] max_md:text-ExLargeSize max_md:leading-[32px] ">
                  <div className="max-w-[362px]">
                    We Provide Other Exclusive Services For Turn Your Business
                    Into A Brand
                  </div>
                </div>

                {otherService.map((cardData, index) => (
                  <Fragment key={index}>
                    <ServiceCard
                      title={cardData.title}
                      desc={cardData.description}
                      background={cardData.background}
                      readMore={cardData.link}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceProdDevelop;
