import React, { useState } from "react";

const InterpretationDefinitions = () => {
  const [Definitions] = useState([
    {
      title: "Account",
      description:
        " means a unique account created for You to access our Service or parts of our Service.",
    },
    {
      title: "Affiliate",
      description:
        " means an entity that controls, is controlled by or is under common control with a party, where 'control' means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.",
    },
    {
      title: "Company",
      description:
        ' (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to IGEEK, 301/A, Avalon The commerical Hub, Aamba Talavadi, Katargam, Surat, Gujarat 395004..',
    },
    {
      title: "Cookies",
      description:
        " are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.",
    },
    {
      title: "Country",
      description: " refers to: Gujarat, India",
    },
    {
      title: "Device",
      description:
        " means any device that can access the Service such as a computer, a cellphone or a digital tablet.",
    },
    {
      title: "Personal",
      description:
        " Data is any information that relates to an identified or identifiable individual.",
    },
    {
      title: "Service",
      description: " refers to the Website.",
    },
    {
      title: "Service Provider",
      description:
        " means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.",
    },
    {
      title: "Usage Data",
      description:
        " refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).",
    },
    {
      title: "Website",
      description: ` refers to iGeek, accessible from https://igeektech.com/`,
    },
    {
      title: "You",
      description:
        " means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
    },
  ]);
  return (
    <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
      <div className="w-full">
        <div className="text-[26px] font-bold text-duskwood max_md:text-[18px] max_md:leading-[32px]">
          Interpretation and Definitions
        </div>
        <div className="pt-[30px] text-[20px] font-semibold leading-[24px] text-bateau max_md:pt-[20px] max_md:text-[16px] max_md:leading-[20px] max_sm:pt-[10px]">
          Interpretation
        </div>
        <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </div>
        <div>
          <div className="pt-[30px] text-[20px] font-semibold leading-[24px] text-bateau max_md:pt-[20px] max_md:text-[16px] max_md:leading-[20px] max_sm:pt-[10px]">
            Definitions
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            For the purposes of this Privacy Policy:
          </div>

          <div className="pl-[15px]">
            {Definitions.map((itm, index) => (
              <div
                key={index}
                className="flex items-start gap-[10px] pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]"
              >
                <span className="mt-[12px] h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
                <p>
                  <span className="font-semibold">{itm.title}</span>{" "}
                  {itm.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterpretationDefinitions;
