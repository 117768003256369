import React, { useState } from "react";
import LOGOSVG from "../assets/common/svgs/iGeek.svg";
import HambergerSVG from "../assets/common/svgs/Hamberger.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ButtonFilled } from "./utils/Buttons";

const Navbar = ({ setIsServiceOpen, setIsMobileMenuOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [MenuList] = useState([
    {
      name: "✨AI",
      link: "/ai-ml",
    },
    {
      name: "Case Study",
      link: "/case-study",
    },
    {
      name: "Services",
      link: "",
    },
    // {
    //   name: "Hire Us",
    //   link: "/hire-us",
    // },
    {
      name: "About Us",
      link: "/about-us",
    },
    {
      name: "Career",
      link: "/career",
    },
  ]);

  return (
    <>
      <div className="m-auto flex h-[80px] max-w-[1240px] items-center max_xl:max-w-[940px] max_sm:h-[70px]">
        <div className="F-JC-AI-CENTER w-full justify-between max_md:justify-center ">
          <div className="absolute left-[40px] hidden max_md:block max_sm:left-[20px]">
            <img draggable="false"
              onClick={() => setIsMobileMenuOpen(true)}
              src={HambergerSVG}
              alt=""
            />
          </div>
          <div
            onClick={() => {
              if (location.pathname === "/") {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              } else {
                navigate("/");
              }
            }}
            className="F-JC-AI-CENTER cursor-pointer"
          >
            <div className="w-[140px] max_sm:w-[120px]">
              <img draggable="false" src={LOGOSVG} alt="" />
            </div>
          </div>

          <div className="F-JC-AI-CENTER flex gap-x-[30px] max_md:hidden">
            {MenuList.map((menu, index) => (
              <div
                key={index}
                onMouseEnter={() => {
                  if (menu.name === "Services") {
                    setIsServiceOpen(true);
                  } else {
                    setIsServiceOpen(false);
                  }
                }}
              >
                <NavLink
                  className="flex h-[50px] w-full items-center no-underline"
                  to={menu.link}
                >
                  {({ isActive }) => (
                    <>
                      <div
                        className={`bg-white text-RegularSize ${
                          (isActive && menu.link !== "") ||
                          (isActive && location.pathname.includes("service"))
                            ? "font-semibold text-duskwood"
                            : "text-black"
                        } cursor-pointer hover:text-duskwood`}
                      >
                        {menu.name}
                      </div>
                    </>
                  )}
                </NavLink>
              </div>
            ))}
            <ButtonFilled
              title="Contact us"
              onClick={() => {
                navigate("/contact-us");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
