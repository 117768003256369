import React from "react";
import BulletIcon from "../assets/common/svgs/Hireus/BulletPointIcon.svg";

const EngagementCard = ({ description, subTopics, title }) => {
  return (
    <>
      <div className="relative h-[506px] w-full  max-w-[610px] rounded-[15px] shadow-[0px_2px_16px_rgba(0,0,0,0.1)] max_md:h-full">
        <div className="py-[40px] pl-[56px] pr-[40px] max_md:py-[20px] max_md:pl-[33px] max_md:pr-[18px]">
          <div className="text-[26px] font-bold leading-[39px] text-bateau max_md:mt-[0px] max_md:text-[18px] max_md:leading-[27px]">
            {title}
          </div>
          <div className="mt-[20px] text-[16px] leading-[26px] text-black-oak max_md:mt-[15px]  max_md:text-[14px] max_md:leading-[22px]">
            {description}
          </div>
          <div className="mt-[30px] flex flex-col gap-[20px] max_md:mt-[15px]">
            {subTopics.map((items, index) => (
              <div key={index} className="flex items-center gap-[10px]">
                <div className="flex self-start pt-[3px]">
                  <img draggable="false"
                    className="w-[18px] min-w-[18px] max_md:w-[15px] max_md:min-w-[15px]"
                    src={BulletIcon}
                    alt="Points"
                  />
                </div>
                <div className="text-MediumSize leading-[21px] text-[#7D7D7D] max_md:text-[12px] max_md:leading-[18px]">
                  {items.desc}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="absolute top-0 left-0 h-full w-[16px] rounded-tl-[15px] rounded-bl-[15px] bg-expressionism-green">
          <div className="h-full max-h-[41px] w-full rounded-tl-[15px] bg-expressionism-green" />
          <div className="flex h-[135px] w-full flex-col justify-between bg-bateau">
            <div className="h-[16px] bg-white" />
            <div className="h-[16px] bg-white" />
          </div>
        </div>
      </div>
    </>
  );
};

export default EngagementCard;
