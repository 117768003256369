import React from "react";

const TechnologyCard = ({ itm }) => {
  return (
    <>
      <div
        style={{
          boxShadow: "3.0244px 3.0244px 12.0976px rgba(0, 0, 0, 0.06)",
        }}
        className="relative mt-[66px] flex h-[260px] w-[400px]  flex-col rounded-[16px] border-[1px] border-serene-sea bg-white-edgar px-[40px]  pb-[40px] pt-[16px] max_md:w-[300px] max_md:px-[20px] max_md:pb-[25px]"
      >
        <div className="absolute left-[50px] top-[-50px]">
          <div
            style={{
              boxShadow: "0px 6.04879px 11.3415px rgba(155, 155, 155, 0.2)",
            }}
            className="flex h-[90px] w-[90px] items-center justify-center rounded-full bg-white"
          >
            <img draggable="false" className="" src={itm.icon} alt="" />
          </div>
        </div>
        <div className="TechCardHeading mb-[36px] ml-[115px] max_md:mb-[28px] max_md:ml-[130px]">
          {itm.name === "DevOps and Cloud" && window.innerWidth < 981
            ? "DevOps"
            : itm.name}
        </div>
        <div className="flex w-full justify-center">
          <div className="grid w-full grid-cols-2 gap-y-[30px] pl-[24px]">
            {itm.tech.map((itm1, index) => (
              <div className="flex items-center gap-x-[10px]" key={index}>
                <img draggable="false" src={itm1.icon} alt="" />
                <div className="TechCardContent">{itm1.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnologyCard;
