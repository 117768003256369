import React from "react";

const BenefitCard = ({ IMAGE = "", title = "" }) => {
  return (
    <div className="flex h-[98px] w-full items-center gap-x-[36px] rounded-[10px] bg-clear-skies pl-[45px] max_xl:gap-x-[20px] max_xl:pl-[35px]">
      <img draggable="false" className="w-[46px] h-[46px]" src={IMAGE} alt="" />
      <div className="text-[17px] max_sm:text-RegularSize font-medium text-bank-vault">{title}</div>
    </div>
  );
};

export default BenefitCard;
