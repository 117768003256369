import React, { Fragment, useState } from "react";
import DeviderLine from "../DeviderLine";
import HireCard from "../HireCard";
import IMG1 from "../../assets/common/svgs/Hireus/HireMobile.svg";
import IMG2 from "../../assets/common/svgs/Hireus/HireWeb.svg";
import IMG3 from "../../assets/common/svgs/Hireus/HireBackend.svg";

const HireUsComp = () => {
  const [CardList, setCardList] = useState([
    {
      title: "Hire Mobile App Developers",
      img: IMG1,
    },
    {
      title: "Hire Web Developers",
      img: IMG2,
    },
    {
      title: "Hire Back-End Developers",
      img: IMG3,
    },
  ]);
  return (
    <>
      <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        <DeviderLine title="Hire US" right="right-[98%]" />
        <div className="mt-[80px] flex justify-between  max_md:mt-[60px] max_md:flex max_md:justify-center">
          {/* <div className="PageContentText w-full max-w-[355px] leading-[24px]  max_md:text-center">
            iGeeks provides top-quality talent solutions to help our clients
            build high-performing teams and achieve their business goals.
          </div> */}
          {/* <div className="max_md:hidden">
            <ButtonOutliedIcon title="START A PROJECT" />
          </div> */}
        </div>
        <div className="mt-[80px] mb-[120px] flex flex-wrap  justify-between max_xl:justify-center max_xl:gap-[22px] max_md:mb-[100px]">
          {CardList.map((itm, index) => (
            <Fragment key={index}>
              <HireCard title={itm.title} img={itm.img} />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default HireUsComp;
