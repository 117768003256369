import React, { useState } from "react";
import HomeBottomBG1 from "../../assets/common/images/HomeBG2.png";
import ServiceSectionComp from "../../Components/ServiceSectionComp";
import Layout from "../../Components/utils/Layout";
import AiMlIMG from "../../assets/common/svgs/service/AiMlIMG.svg";
import ToolIMG from "../../assets/common/images/ToolImg.png";

const ServiceAiMl = () => {
  const [serviceData] = useState({
    DeviderTitle: "WHY IGEEK",
    Heading: "Why choose iGeek for AI & ML services?",
    Image: AiMlIMG,
    description:
      "At iGeek, our AI & ML services harness the latest in artificial intelligence and machine learning innovation to provide tailored, cutting-edge solutions. With a focus on leveraging AI & ML to solve real-world challenges, iGeek is your partner in navigating the future of technology and securing your place at the forefront of industry evolution.",
    cards: [
      {
        Image: ToolIMG,
        title: "Chatbot Development",
        background: "bg-placebo-turquoise",
        description:
          "Revolutionize your business with our domain specific AI chatbots boosting engagement, automating support, and fueling growth efficiently.",
      },
      {
        Image: ToolIMG,
        title: "Custom Model Training",
        background: "bg-clear-skies",
        description:
          "Customize success with our Machine Learning model training services, designed to drive innovation and efficiency with our expertly developed solutions.",
      },
      {
        Image: ToolIMG,
        title: "Retrieval Augmented Generation",
        background: "bg-placebo-turquoise",
        description:
          "Advance your capabilities with our Retrieval Augmented Generation service, integrating cutting-edge AI to enhance data retrieval and creativity in your domain.",
      },
      {
        Image: ToolIMG,
        background: "bg-clear-skies",
        title: "Computer Vision",
        description: "Unlock the power of sight with our Computer Vision development services, leveraging to deliver user specific insights and innovation from visual data.",
      },
      {
        Image: ToolIMG,
        background: "bg-placebo-turquoise",
        title: "Generative AI",
        description:
          "Shape the future with our Generative AI development services, crafting bespoke AI solutions that innovate and transform within your specific domain.",
      },
    ],
  });

  return (
    <>
      <Layout>
        <div className="">
          <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
            <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
              <img draggable="false" className="h-full object-cover" src={HomeBottomBG1} alt="" />
            </div>

            <div className="flex flex-col items-center justify-center text-center">
              <div className="HeadingText mt-[100px] max-w-[780px] max_md:px-[50px] max_sm:px-[20px]">
                Beyond Algorithms: Crafting the Future with AI & ML Excellence.
              </div>
              <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
                Explore the power of AI & ML with us - transforming data into insights and innovation. We're your partners in driving efficiency and pioneering industry-leading solutions.
              </div>
            </div>
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <ServiceSectionComp
              maxWidth={"max-w-[258px]"}
              serviceData={serviceData}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ServiceAiMl;
