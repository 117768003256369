import Rightful from "../assets/common/images/CaseStudy/Rightful.png";
import KridaforBadminton from "../assets/common/images/CaseStudy/KridaforBadminton.png";
import InnDex from "../assets/common/images/CaseStudy/InnDex.png";
import EthikaGroup from "../assets/common/images/CaseStudy/EthikaGroup.png";
import BuildScan from "../assets/common/images/CaseStudy/BuildScan.png";
import TopTennisTracker from "../assets/common/images/CaseStudy/TopTennisTracker.png";
import Layer3labs from "../assets/common/images/CaseStudy/Layer3labs.png";
import Namma11 from "../assets/common/images/CaseStudy/Namma11.png";
import LRTS from "../assets/common/images/CaseStudy/LRTS.png";
import Homeez from "../assets/common/images/CaseStudy/Homeez.png";
import ZackSaloon from "../assets/common/images/CaseStudy/ZackSaloon.png";
import Sendatradie from "../assets/common/images/CaseStudy/Sendatradie.png";
import WADWAD from "../assets/common/images/CaseStudy/WADWAD.png";
import Myrios from "../assets/common/images/CaseStudy/Myrios.png";
import JustthinkAI from "../assets/common/images/CaseStudy/JustthinkAI.png";
import Zix from "../assets/common/images/CaseStudy/Zix.png";
import Tuipoint from "../assets/common/images/CaseStudy/Tuipoint.png";
import DrPrognosis from "../assets/common/images/CaseStudy/DrPrognosis.png";
import Stellaracademic from "../assets/common/images/CaseStudy/Stellaracademic.png";
import GrapeID from "../assets/common/images/CaseStudy/GrapeID.png";
import Doze from "../assets/common/images/CaseStudy/Doze.png";
import Dcdial from "../assets/common/images/CaseStudy/dcdial.png";
import Tironem from "../assets/common/images/CaseStudy/tironem.png";
import Scimetic from "../assets/common/images/CaseStudy/scimetic.png";
import Scimeticapp from "../assets/common/images/CaseStudy/scimeticapp.png";
import Snapit from "../assets/common/images/CaseStudy/snapit.png";
import Algobrix from "../assets/common/images/CaseStudy/algobrix.png";
import Algobrixapp from "../assets/common/images/CaseStudy/algobrixapp.png";
import Balanceplay from "../assets/common/images/CaseStudy/balanceplay.png";
import Broytekart from "../assets/common/images/CaseStudy/broytekart.png";
import Engagex from "../assets/common/images/CaseStudy/engagex.png";
import RealSpot from "../assets/common/images/CaseStudy/realSpot.png";
import Rersala from "../assets/common/images/CaseStudy/rersala.png";
import Wadweb from "../assets/common/images/CaseStudy/wadweb.png";
import Myriosweb from "../assets/common/images/CaseStudy/myriosweb.png";
import alloyForwardingSoftware from "../assets/common/images/CaseStudy/alloyForwardingSoftware.png";
import Callahead from "../assets/common/images/CaseStudy/Callahead.png";
import Android from "../assets/common/images/Technology/android.png";
import React from "../assets/common/images/Technology/react.png";
import Ios from "../assets/common/images/Technology/ios.png";
import Flutter from "../assets/common/images/Technology/flutter.png";
import Ionic from "../assets/common/images/Technology/ionic.png";
import Angular from "../assets/common/images/Technology/angular.png";
import Next from "../assets/common/images/Technology/next.png";
import Vue from "../assets/common/images/Technology/vue.png";
import Gatsby from "../assets/common/images/Technology/gatsby.png";
import Node from "../assets/common/images/Technology/node.png";
import Python from "../assets/common/images/Technology/python.png";
import Asp from "../assets/common/images/Technology/asp.png";
import Php from "../assets/common/images/Technology/php.png";
import Qraphql from "../assets/common/images/Technology/qraphql.png";
import Mern from "../assets/common/images/Technology/mern.png";
import Fullstack from "../assets/common/images/Technology/fullstack.png";
import Mean from "../assets/common/images/Technology/mean.png";
import Devops from "../assets/common/images/Technology/devops.png";
import Software from "../assets/common/images/Technology/software.png";
import RersalaTech from "../assets/common/images/Technology/rersala.png";
import simeticTech from "../assets/common/images/Technology/simetic.png";

export const AllProjects = [
  {
    IMG: Rersala,
    title: 'Resala.ai',
    description:
      'Resala.ai is chrome extension powered by GPT-4 and Bard used for replying emails, summarize youtube video, smart chat, and chat with document.',
    link: 'https://resala.ai',
    technologies: [
      'React JS',
      'GPT-4',
      'Python',
      'LangChain',
      'Bard',
      'Gemini',
      'Hugging Face',
    ],
    filter: 'Extensions',
  },
  {
    IMG: JustthinkAI,
    title: 'Just think AI',
    description:
      "Just Think Al - Your personal Al assistant that's always ready to help and Boost productivity and knowledge today!",
    link: 'https://apps.apple.com/in/app/just-think-ai/id6446346817',
    technologies: ['React Native', 'GPT-4'],
    filter: 'Mobile Application',
  },
  {
    IMG: Rightful,
    title: 'Rightful',
    description:
      'Rightful is a first of its kind legal collaboration platform where you can find barristers, paralegals and solicitors for legal services.',
    link: 'https://www.rightful.com.au/',
    technologies: ['React Js', 'Python', 'GCP', 'Apryse', 'Auth0', 'Recoil'],
    filter: 'Website',
  },
  {
    IMG: Dcdial,
    title: 'DCDial',
    description:
      'We are a team of communications innovators who understand the increasing demand for flexible, feature-rich and cost-effective customer contact strategies.',
    link: 'https://www.dcdial.com/',
    technologies: ['React JS', 'Tailwind CSS', 'Node JS', 'WebRTC'],
    filter: 'Extensions',
  },
  {
    IMG: KridaforBadminton,
    title: 'Krida for Badminton',
    description:
      'Krida is a fully integrated digital platform to manage your sport and promote sporting excellence, beginning with badminton.',
    link: 'https://play.google.com/store/apps/details?id=com.sports.krida&hl=en&gl=US',
    technologies: ['React Native', 'Firebase', 'AWS'],
    filter: 'Mobile Application',
  },
  {
    IMG: EthikaGroup,
    title: 'Ethika Group',
    description:
      'Ethika is redefining what it means to work in a professional services firm and how it looks and feels to partner with one.',
    link: 'https://ethikagroup.com.au/',
    technologies: ['Wordpress', 'Elementor', 'Pipedrive'],
    filter: 'Website',
  },
  {
    IMG: InnDex,
    title: 'InnDex',
    description:
      'innDex is the end-to-end digital workforce management platform for construction to increase productivity for workers. ',
    link: 'https://play.google.com/store/apps/details?id=me.innDex.mobile&hl=en_US',
    technologies: ['Ionic', 'Node JS'],
    filter: 'Mobile Application',
  },
  {
    IMG: BuildScan,
    title: 'BuildScan',
    description:
      'BuildScan has been developed to work for a number of different roles within many industries for task management and collaborate.',
    link: 'https://apps.apple.com/us/app/buildscan-snag-list-tracker/id1536535155',
    technologies: ['Android', 'Laravel'],
  },
  {
    IMG: TopTennisTracker,
    title: 'Top Tennis Tracker',
    description:
      'Top Tennis Tracker is a tennis-score tracking app aimed at lifting your player to the next level. You can check graphs and insides of tennis player in games.',
    link: 'https://play.google.com/store/apps/details?id=com.toptennistracker&hl=en_IN&gl=US',
    technologies: ['Android', 'Python'],
    filter: 'Mobile Application',
  },
  {
    IMG: Namma11,
    title: 'Namma11',
    description:
      'Namma11 is an online fantasy game designed for the fans who like to showcase their cricket knowledge and analytical skills.',
    link: 'https://apps.apple.com/app/namma11/id1596449910',
    technologies: ['Ionic', 'Node JS'],
    filter: 'Mobile Application',
  },
  {
    IMG: Layer3labs,
    title: 'Layer3labs',
    description:
      'Layer3labs is a software development company and advisory firm that will help you to navigate the world of Web3.',
    link: 'https://www.layer3labs.io/',
    technologies: ['React JS', 'Next JS'],
    filter: 'Website',
  },
  {
    IMG: Homeez,
    title: 'Homeez',
    description:
      'Homeez is a platform that connects homeowners directly to suppliers in their entire renovation process in Singapore.',
    link: 'https://www.homeez.com/',
    technologies: ['React JS', 'Python', 'Yolo', 'NLP'],
    filter: 'Website',
  },
  {
    IMG: LRTS,
    title: 'LRTS',
    description:
      'LRTS is a gifting app in UAE where customers can place order and send gifts to their friends and family on this app.',
    link: 'https://play.google.com/store/apps/details?id=com.simplistq.lrts&hl=en_IN&gl=US',
    technologies: ['Flutter', 'Node JS'],
    filter: 'Mobile Application',
  },
  {
    IMG: ZackSaloon,
    title: "Z' ack Salon",
    description:
      "Z' ack Salon help Working professionals, business owners & visionary people by providing personalized skin & hair care.",
    link: 'https://play.google.com/store/apps/details?id=com.zack.salon',
    technologies: ['Flutter', 'Node JS'],
    filter: 'Mobile Application',
  },
  {
    IMG: Sendatradie,
    title: 'Sendatradie',
    description:
      'Sendatradie makes it easier than ever for service business owners to run their business, save time, make their team more efficient and their business.',
    link: 'https://www.sendatradie.com/',
    technologies: ['React JS', 'Node JS'],
    filter: 'Website',
  },
  {
    IMG: WADWAD,
    title: 'WAD? WAD!',
    description:
      'WAD WAD is a platform where dog owners and dog walkers interact with each other for their pet’s activity and book their walks.',
    link: '',
    technologies: ['Flutter', 'Node JS', 'AWS'],
    filter: 'Mobile Application',
  },
  {
    IMG: Myrios,
    title: 'Myrios',
    description:
      'Myrios is app for refugee and shelter owners who are in Ukraine in need of help from donors. By using this app donors can donate to refugees and shelters.',
    link: 'http://myrios.info/',
    technologies: ['Flutter', 'Laravel', 'AWS'],
    filter: 'Mobile Application',
  },
  {
    IMG: Zix,
    title: 'Zix',
    description:
      'ZIX is warehouse and logistics managing software which works with several freight transfer companies for air, water and land transfers.',
    link: 'https://abc.forwarding-live.com/',
    technologies: ['Angular', 'ASP.NET'],
    filter: 'Website',
  },
  {
    IMG: Tuipoint,
    title: 'Tuipoint',
    description:
      'Discover Barbershop in your location and smart queue in the app, no need to wait in store. Advance book your barber with your preferred time slot.',
    link: 'https://play.google.com/store/apps/details?id=com.tuipoint.app&hl=en&gl=US',
    technologies: ['Ionic', 'Laravel', 'AWS'],
    filter: 'Mobile Application',
  },
  {
    IMG: DrPrognosis,
    title: 'Progenesis',
    description:
      'Progenesis is Hospital management app where doctors, nurses, lab agents, pharmacist, etc. can work today for patient management.',
    link: '',
    technologies: ['Android', 'Node JS'],
    filter: 'Mobile Application',
  },
  {
    IMG: Stellaracademic,
    title: 'Stellar Academic',
    description:
      'Stellar Academic Was designed for Establishing And Counseling Families And Students In How To Best Organize Their Academic Needs.',
    link: 'https://stellaracademic.com/',
    technologies: ['Angular', 'php'],
    filter: 'Website',
  },
  {
    IMG: GrapeID,
    title: 'Grape ID',
    description:
      'Grape ID™ puts YOU in control to reclaim your online privacy, security, & actually get paid for your time and data.',
    link: 'https://apps.apple.com/us/app/grape-id-inc/id1587046966',
    technologies: ['Ionic', 'Node JS'],
    filter: 'Mobile Application',
  },
  {
    IMG: Doze,
    title: 'Doze',
    description:
      "Doze is a free sleep self coaching app that aims to support you to achieve a better night’s rest for children's and young adults",
    link: 'https://play.google.com/store/apps/details?id=com.tochtech.dozeapp&hl=en_IN&gl=US',
    technologies: ['Flutter', 'Node JS'],
    filter: 'Mobile Application',
  },
  {
    IMG: Scimetic,
    title: 'Scimetic',
    description:
      'Scimetic is a smart irrigation management platform where it connects with sensors, generators, switched, etc. connect in agricultural land to manage that from office.',
    link: 'https://www.scimetic.com/',
    technologies: ['React JS', 'Node JS', 'AWS', 'Docker'],
    filter: 'Website',
  },
  {
    IMG: Tironem,
    title: 'Tironem',
    description:
      "Tironem's AI does all the screening work for you. Instantly screen all of your candidates as they apply, and then automatically short list the top talent.",
    link: 'https://tironem.ai/',
    technologies: ['React JS', '.Net', 'Azure', 'C#'],
    filter: 'Website',
  },
  {
    IMG: Scimeticapp,
    title: 'Scimetic app',
    description:
      'Scimetic is a smart irrigation management platform where it connects with sensors, generators, switched, etc. connect in agricultural land to manage that from office.',
    link: 'https://www.scimetic.com/',
    technologies: ['Flutter', 'Node JS'],
    filter: 'Mobile Application',
  },
  {
    IMG: Snapit,
    title: 'Snapit',
    description:
      'Snapit aim to create the go to web app for all your screenshots. Snapit does not require technical skills from a user, meaning that anyone can make beautiful screenshots in seconds.',
    link: 'https://www.snapit.gg/',
    technologies: ['Flutter', 'Node JS'],
    filter: 'Mobile Application',
  },
  {
    IMG: Algobrixapp,
    title: 'Algobrix App',
    description:
      'Algobrix is a coding game for children that teaches kids programming in a fun way. Simply build a robot, code it with the coding bricks.',
    link: 'https://www.algobrix.com/',
    technologies: ['Android', 'Bluetooth Low Energy'],
    filter: 'Mobile Application',
  },
  {
    IMG: Engagex,
    title: 'Engagex',
    description:
      'engageX is chrome extension for LinkedIn and Twitter to connect and engage with people with the use of Artificial Intelligence and grow more audience.',
    link: 'https://www.engagex.social/',
    technologies: ['React JS', 'GPT-4', 'php'],
    filter: 'Extensions',
  },
  {
    IMG: Balanceplay,
    title: 'Balance Play',
    description:
      'Create high-quality 3D models from just scanning the space/object with Android device. You can share and save models as per your requirement',
    link: 'https://play.google.com/store/apps/details?id=com.luxolis.balanceplay&hl=en-IN',
    technologies: ['Android', 'Python'],
    filter: 'Mobile Application',
  },
  {
    IMG: Algobrix,
    title: 'Algobrix(Desktop App)',
    description:
      'Algobrix is a coding game for children that teaches kids programming in a fun way. Simply build a robot, code it with the coding bricks.',
    link: 'https://www.algobrix.com/',
    technologies: ['Flutter', 'Bluetooth Low Energy'],
    filter: 'Mobile Application',
  },
  {
    IMG: Wadweb,
    title: 'WAD? WAD! (Web)',
    description:
      'WAD? WAD! admin panel is specially designed for managing all the users on app for their activities, subscription, report, etc.',
    link: '',
    technologies: ['React JS', 'Node JS'],
    filter: 'Website',
  },
  {
    IMG: Myriosweb,
    title: 'Myrios (Web)',
    description:
      'Myrios admin panel is for managing users of myrios for their profile verification, reports and profile activities.',
    link: 'https://admin.myrios.info/',
    technologies: ['Angular', 'Laravel'],
    filter: 'Website',
  },
  {
    IMG: Broytekart,
    title: 'Broytekart',
    description:
      'With Brøyte we have worked for a long time to perfect our plowing and other services to be ordered and carried out easily and without unnecessary administration.',
    link: 'https://apps.apple.com/no/app/hyttebr%C3%B8yting/id1303138779',
    technologies: ['React Native', 'Node JS'],
    filter: 'Mobile Application',
  },
  {
    IMG: RealSpot,
    title: 'RealSpot',
    description:
      'RealSpot is the next generation of social media, one that serves you as an individual, a business, and a community.',
    link: 'https://apps.apple.com/us/app/realspot-be-your-real-self/id1540588566',
    technologies: ['iOS', 'Node JS'],
    filter: 'Mobile Application',
  },
  {
    IMG: alloyForwardingSoftware,
    title: 'Alloy Forwarding Software',
    description:
      'The Leading Platform in Logistics and Supply Chain Automation Designed by industry professionals, Alloy Forwarding Software makes your job easier, faster, and cost-effective.',
    link: 'https://alloyforwardingsoftware.com/',
    technologies: ['Angular', 'ASP.NET'],
    filter: 'Website',
  },
  {
    IMG: Callahead,
    title: 'CALLAHEAD',
    description:
      'CALLAHEAD supplies sanitation equipment of every description including outdoor units, highrise units, handicap units, trailer units, VIP toilets, comfort stations and fuel oil delivery.',
    link: 'https://www.callahead.com/index.html',
    technologies: ['React Native', 'Node JS', 'Google Map', 'Socket.io'],
    filter: 'Mobile Application',
  },
];

export const technologies = {
  android: {
    title: "Hire Android App <br/> Developers",
    img: Android,
    description:
      "We have a team of highly experience and talented Android developers who can help you build high-performing and customized applications that cater to your unique business needs. Our developers have worked with a range of clients, from startups to large enterprises, and have a deep understanding of the Android ecosystem and technologies.",
    subTopics: [
      {
        desc: "Custom App Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: BuildScan,
        title: "BuildScan",
        description:
          "BuildScan has been developed to work for a number of different roles within many industries for task management and collaborate.",
        link: "https://apps.apple.com/us/app/buildscan-snag-list-tracker/id1536535155",
        technologies: ["Android", "Laravel"],
      },
      {
        IMG: TopTennisTracker,
        title: "Top Tennis Tracker",
        description:
          "Top Tennis Tracker is a tennis-score tracking app aimed at lifting your player to the next level. You can check graphs and insides of tennis player in games.",
        link: "https://play.google.com/store/apps/details?id=com.toptennistracker&hl=en_IN&gl=US",
        technologies: ["Android", "Python"],
      },
    ],
  },
  reactnative: {
    title: "Hire React Native <br/> Developers",
    img: React,
    description:
      "Looking for a team of skilled React Native developers? Our experts deliver top-notch cross-platform applications, tailored to your specific requirements. With timely delivery, clear communication, and a focus on excellence, we bring your app ideas to life with our expertise in React Native development.",
    subTopics: [
      {
        desc: "App Migration and Upgrade",
      },
      {
        desc: "Cross-platform App Development",
      },
      {
        desc: "Rapid Development and Deployment",
      },
      {
        desc: "Server Side API Development",
      },
    ],
    projects: [
      {
        IMG: JustthinkAI,
        title: "Just think AI",
        description:
          "Just Think Al - Your personal Al assistant that's always ready to help and Boost productivity and knowledge today!",
        link: "https://apps.apple.com/in/app/just-think-ai/id6446346817",
        technologies: ["React Native", "GPT-4"],
      },
      {
        IMG: KridaforBadminton,
        title: "Krida for Badminton",
        description:
          "Krida is a fully integrated digital platform to manage your sport and promote sporting excellence, beginning with badminton.",
        link: "https://play.google.com/store/apps/details?id=com.sports.krida&hl=en&gl=US",
        technologies: ["React Native", "Firebase", "AWS"],
      },
    ],
  },
  ios: {
    title: "Hire iOS <br/> Developers",
    img: Ios,
    description:
      "Our team offers top-notch iOS development services, designing and crafting custom applications specifically for Apple's iOS platform. Harnessing the power of Swift and Objective-C, along with utilizing the full range of iOS SDKs, we build secure, efficient, and user-friendly apps tailored to your unique requirements.",
    subTopics: [
      {
        desc: "Custom App Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Migration & Enhancement",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: BuildScan,
        title: "BuildScan",
        description:
          "BuildScan has been developed to work for a number of different roles within many industries for task management and collaborate.",
        link: "https://apps.apple.com/us/app/buildscan-snag-list-tracker/id1536535155",
        technologies: ["Android", "Laravel"],
      },
      {
        IMG: RealSpot,
        title: "RealSpot",
        description:
          "RealSpot is the next generation of social media, one that serves you as an individual, a business, and a community.",
        link: "https://apps.apple.com/us/app/realspot-be-your-real-self/id1540588566",
        technologies: ["iOS", "Node JS"],
      },
    ],
  },
  flutter: {
    title: "Hire Flutter <br/> Developers",
    img: Flutter,
    description:
      "Our Flutter development services specialize in creating fast, beautiful, and responsive applications for all platforms. Leveraging the Flutter SDK, our developers craft high-performance apps with a single codebase, reducing both development time and cost. Our team is equipped to handle any advanced functionalities or custom integrations that your app might require.",
    subTopics: [
      {
        desc: "Custom App Development",
      },
      {
        desc: "Cross Platform App Development",
      },
      {
        desc: "Migration & Enhancement",
      },
      {
        desc: "100% In house Team",
      },
    ],
    projects: [
      {
        IMG: WADWAD,
        title: "WAD? WAD!",
        description:
          "WAD WAD is a platform where dog owners and dog walkers interact with each other for their pet’s activity and book their walks.",
        link: "",
        technologies: ["Flutter", "Node JS", "AWS"],
      },
      {
        IMG: Myrios,
        title: "Myrios",
        description:
          "Myrios is app for refugee and shelter owners who are in Ukraine in need of help from donors. By using this app donors can donate to refugees and shelters.",
        link: "",
        technologies: ["Flutter", "Laravel", "AWS"],
      },
    ],
  },
  ionic: {
    title: "Hire Ionic <br/> Developers",
    img: Ionic,
    description:
      "Our Ionic development services are designed to deliver highly interactive and performance-optimized applications across multiple platforms. Leveraging the Ionic framework, our developers craft scalable, secure, and visually stunning apps using a single, shared codebase. Our proficient developers are committed to delivering top-quality, cross-platform solutions.",
    subTopics: [
      {
        desc: "PWA Development",
      },
      {
        desc: "Hybrid Mobile App Development",
      },
      {
        desc: "UI/UX Strategy Development",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: InnDex,
        title: "InnDex",
        description:
          "innDex is the end-to-end digital workforce management platform for construction to increase productivity for workers. ",
        link: "https://play.google.com/store/apps/details?id=me.innDex.mobile&hl=en_US",
        technologies: ["Ionic", "Node JS"],
      },
      {
        IMG: Namma11,
        title: "Namma11",
        description:
          "Namma11 is an online fantasy game designed for the fans who like to showcase their cricket knowledge and analytical skills.",
        link: "https://apps.apple.com/app/namma11/id1596449910",
        technologies: ["Ionic", "Node JS"],
      },
    ],
  },
  angular: {
    title: "Hire Angular <br/> Developers",
    img: Angular,
    description:
      "Our Angular development services deliver high-quality, dynamic web applications tailored to your business needs. We leverage Angular's robust framework to build scalable, efficient, and user-friendly solutions. Our team manages the full cycle, from design and development to testing and support, ensuring seamless web experiences for your users.",
    subTopics: [
      {
        desc: "Custom App Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Migration & Enhancement",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: Stellaracademic,
        title: "Stellar Academic",
        description:
          "Stellar Academic Was designed for Establishing And Counseling Families And Students In How To Best Organize Their Academic Needs.",
        link: "https://stellaracademic.com/",
        technologies: ["Angular", "php"],
      },
      {
        IMG: Zix,
        title: "Zix",
        description:
          "ZIX is warehouse and logistics managing software which works with several freight transfer companies for air, water and land transfers.",
        link: "https://abc.forwarding-live.com/",
        technologies: ["Angular", "ASP.NET"],
      },
    ],
  },
  react: {
    title: "Hire React JS <br/> Developers",
    img: React,
    description:
      "We connect you with highly skilled and experienced ReactJS developers who can bring your ideas to life. Whether you need a dedicated developer for a short-term project or want to expand your development team, we have the talent to meet your needs. Say goodbye to the hassles of recruitment and onboarding, and let us handle the process for you.",
    subTopics: [
      {
        desc: "Custom Web Development",
      },
      {
        desc: "Browser Extension Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: Rightful,
        title: "Rightful",
        description:
          "Rightful is a first of its kind legal collaboration platform where you can find barristers, paralegals and solicitors for legal services.",
        link: "https://www.rightful.com.au/",
        technologies: ['React Js', 'Python', 'GCP', 'Apryse', 'Auth0', 'Recoil'],
      },
      {
        IMG: Homeez,
        title: "Homeez",
        description:
          "Homeez is a platform that connects homeowners directly to suppliers in their entire renovation process in Singapore.",
        link: "https://www.homeez.com/",
        technologies: ['React JS', 'Python', 'Yolo', 'NLP'],
      },
    ],
  },
  gatsby: {
    title: "Hire Gatsby <br/> Developers",
    img: Gatsby,
    description:
      "Gatsby.js is a powerful framework that leverages React and GraphQL to deliver blazing-fast websites, and our developers are well-versed in its intricacies. Whether you need a Gatsby.js specialist for a short-term or long-term project or want to assemble a dedicated development team, we have the talent to suit your requirements.",
    subTopics: [
      {
        desc: "Custom Web Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Migration & Enhancement",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [],
  },
  vue: {
    title: "Hire Vue JS <br/> Developers",
    img: Vue,
    description:
      "We understand the significance of creating interactive and responsive user interfaces, and our developers are experts in harnessing the power of Vue.js to deliver exceptional results. Whether you require a Vue.js specialist for a specific project or want to build a dedicated development team, we've got you covered.",
    subTopics: [
      {
        desc: "Desktop App Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Browser Extension Development",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [],
  },
  nextjs: {
    title: "Hire Next JS <br/> Developers",
    img: Next,
    description:
      "Looking to supercharge your web applications with Next.js? Our Next.js developer hiring services are your gateway to top-notch talent. Next.js is a powerful framework that enables server-side rendering, static site generation, and seamless client-side routing, and our developers are experts in harnessing its capabilities.",
    subTopics: [
      {
        desc: "Custom Web Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Backend Development",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: Layer3labs,
        title: "Layer3labs",
        description:
          "Layer3labs is a software development company and advisory firm that will help you to navigate the world of Web3.",
        link: "https://www.layer3labs.io/",
        technologies: ["React JS", "Next JS"],
      },
    ],
  },
  node: {
    title: "Hire Node JS <br/> Developers",
    img: Node,
    description:
      "Our Node JS developer hiring services are your gateway to exceptional talent. Node JS is a versatile runtime environment that enables server-side scripting and scalable network applications, and our developers are proficient in leveraging its potential. Whether you need a skilled Node.js developer for a project or to build a dedicated team, we have you covered.",
    subTopics: [
      {
        desc: "Backend Development",
      },
      {
        desc: "Microservices",
      },
      {
        desc: "Migration & Enhancement",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: Sendatradie,
        title: "Sendatradie",
        description:
          "Sendatradie makes it easier than ever for service business owners to run their business, save time, make their team more efficient and their business.",
        link: "https://www.sendatradie.com/",
        technologies: ["React JS", "Node JS"],
      },
      {
        IMG: WADWAD,
        title: "WAD? WAD!",
        description:
          "WAD WAD is a platform where dog owners and dog walkers interact with each other for their pet’s activity and book their walks.",
        link: "",
        technologies: ["Flutter", "Node JS", "AWS"],
      },
    ],
  },
  python: {
    title: "Hire Python <br/> Developers",
    img: Python,
    description:
      "Our Python developer hiring services are tailored to find the best talent for your projects. Python is a versatile and powerful language, and our developers are well-versed in its various frameworks and libraries. Whether you need a Python specialist for web development, data analysis, machine learning, or any other application, we've got you covered.",
    subTopics: [
      {
        desc: "AI & ML App Development",
      },
      {
        desc: "Chatbot Development",
      },
      {
        desc: "Backend Services",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: Rightful,
        title: "Rightful",
        description:
          "Rightful is a first of its kind legal collaboration platform where you can find barristers, paralegals and solicitors for legal services.",
        link: "https://www.rightful.com.au/",
        technologies: ['React Js', 'Python', 'GCP', 'Apryse', 'Auth0', 'Recoil'],
      },
      {
        IMG: RersalaTech,
        title: "Resala.ai",
        description:
          "Resala.ai is chrome extension powered by GPT-4 and Bard used for replying emails, summarize youtube video, smart chat, and chat with document.",
        link: "https://resala.ai",
        technologies: ["React JS", "GPT-4", "php", "LangChain", "Bard"],
      },
    ],
  },
  aspnet: {
    title: "Hire ASP.NET <br/> Developers",
    img: Asp,
    description:
      "ASP.NET is a powerful framework for web development, and our developers are well-versed in its various components, including ASP.NET Core and ASP.NET MVC. Whether you need a dedicated ASP.NET developer for a specific project or want to assemble a full-fledged development team, we have the expertise to meet your requirements.",
    subTopics: [
      {
        desc: "Desktop App Development",
      },
      {
        desc: "Azure Services",
      },
      {
        desc: "Backend Development",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: Tironem,
        title: "Tironem",
        description:
          "Tironem's AI does all the screening work for you. Instantly screen all of your candidates as they apply, and then automatically short list the top talent.",
        link: "https://tironem.ai/",
        technologies: ["React JS", ".Net", "Azure", "C#"],
      },
      {
        IMG: Zix,
        title: "Zix",
        description:
          "ZIX is warehouse and logistics managing software which works with several freight transfer companies for air, water and land transfers.",
        link: "https://abc.forwarding-live.com/",
        technologies: ["Angular", "ASP.NET"],
      },
    ],
  },
  php: {
    title: "Hire php <br/> Developers",
    img: Php,
    description:
      "php is a widely-used scripting language for web development, and our developers are highly skilled in harnessing its potential. Whether you need a php specialist for custom web development, e-commerce solutions, content management systems, or any other project, we can connect you with the right talent.",
    subTopics: [
      {
        desc: "Full Stack Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "CRM Development",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: Stellaracademic,
        title: "Stellar Academic",
        description:
          "Stellar Academic Was designed for Establishing And Counseling Families And Students In How To Best Organize Their Academic Needs.",
        link: "https://stellaracademic.com/",
        technologies: ["Angular", "php"],
      },
      {
        IMG: Dcdial,
        title: "dcdial",
        description:
          "We are a team of communications innovators who understand the increasing demand for flexible, feature-rich and cost-effective customer contact strategies.",
        link: "https://www.dcdial.com/",
        technologies: ["React JS", "Chat GPT", "php"],
      },
    ],
  },
  graphql: {
    title: "Hire GraphQL <br/> Developers",
    img: Qraphql,
    description:
      "GraphQL is a powerful query language that enables efficient data retrieval and manipulation, and our developers are experts in its implementation. Whether you need a dedicated GraphQL developer for a specific project or want to build a team of GraphQL specialists. Let us connect you with the right developers to transform your web applications.",
    subTopics: [
      {
        desc: "Backend Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Migration & Enhancement",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [],
  },
  mernstack: {
    title: "Hire MERN stack <br/> Developers",
    img: Mern,
    description:
      "Our MERN stack developer hiring services are designed to connect you with top-notch talent proficient in MongoDB, Express, React, and Node JS. The MERN stack is a powerful combination for developing scalable and feature-rich applications, and our developers are experts in harnessing its potential.",
    subTopics: [
      {
        desc: "Custom App Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Scalable Services",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: simeticTech,
        title: "Scimetic",
        description:
          "Scimetic is a smart irrigation management platform where it connects with sensors, generators, switched, etc. connect in agricultural land to manage that from office.",
        link: "https://www.scimetic.com/",
        technologies: ["React JS", "Node JS", "AWS"],
      },
      {
        IMG: Wadweb,
        title: "WAD? WAD! (Web)",
        description:
          "WAD? WAD! admin panel is specially designed for managing all the users on app for their activities, subscription, report, etc.",
        link: "",
        technologies: ["React JS", "Node JS", "Express"],
      },
    ],
  },
  fullstack: {
    title: "Hire Full stack <br/> Developers",
    img: Fullstack,
    description:
      "Full stack developers are skilled in both front-end and back-end technologies, making them versatile and capable of handling entire projects from start to finish. By partnering with us, you gain access to a pool of skilled developers who can hit the ground running, adapt quickly to your workflows, and deliver high-quality solutions.",
    subTopics: [
      {
        desc: "Custom App Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Migration & Enhancement",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: Tironem,
        title: "Tironem",
        description:
          "Tironem's AI does all the screening work for you. Instantly screen all of your candidates as they apply, and then automatically short list the top talent.",
        link: "https://tironem.ai/",
        technologies: ["React JS", ".Net", "Azure", "C#"],
      },
      {
        IMG: Wadweb,
        title: "WAD? WAD! (Web)",
        description:
          "WAD? WAD! admin panel is specially designed for managing all the users on app for their activities, subscription, report, etc.",
        link: "",
        technologies: ["React JS", "Node JS", "Express"],
      },
    ],
  },
  meanstack: {
    title: "Hire MEAN stack <br/> Developers",
    img: Mean,
    description:
      "Our MEAN developer augmentation services provide you with skilled developers proficient in MongoDB, Express, Angular, and Node JS. MEAN stack is a popular choice for building robust and scalable web applications, and our experienced developers seamlessly integrate into your existing team to complement your efforts.",
    subTopics: [
      {
        desc: "Custom App Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Migration & Enhancement",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: Myriosweb,
        title: "Myrios (Web)",
        description:
          "Myrios admin panel is for managing users of myrios for their profile verification, reports and profile activities.",
        link: "https://admin.myrios.info/",
        technologies: ["Angular", "Laravel"],
      },
    ],
  },
  devops: {
    title: "Hire Devops <br/> Developers",
    img: Devops,
    description:
      "DevOps engineers play a crucial role in streamlining the development and deployment pipelines, and our experienced professionals seamlessly integrate into your team to boost efficiency and collaboration. By partnering with us, you gain access to skilled DevOps engineers who can adapt quickly to your workflows, and ensure smooth project delivery.",
    subTopics: [
      {
        desc: "CI-CD Management",
      },
      {
        desc: "Web Hosting",
      },
      {
        desc: "Server Management",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [],
  },
  software: {
    title: "Hire Software <br/> Developers",
    img: Software,
    description:
      "We provide access to a pool of highly skilled and experienced software developers who can seamlessly integrate into your existing team and contribute their expertise to your projects. Whether you need resources to meet project deadlines or require specialized skills for tasks, our developers can adapt quickly to your workflows and deliver high-quality results.",
    subTopics: [
      {
        desc: "Custom App Development",
      },
      {
        desc: "MVP Development",
      },
      {
        desc: "Migration & Enhancement",
      },
      {
        desc: "Support & Maintenance",
      },
    ],
    projects: [
      {
        IMG: Rersala,
        title: "Resala.ai",
        description:
          "Resala.ai is chrome extension powered by GPT-4 and Bard used for replying emails, summarize youtube video, smart chat, and chat with document.",
        link: "https://resala.ai",
        technologies: ["React JS", "GPT-4", "php", "LangChain", "Bard"],
      },
      {
        IMG: Scimetic,
        title: "Scimetic",
        description:
          "Scimetic is a smart irrigation management platform where it connects with sensors, generators, switched, etc. connect in agricultural land to manage that from office.",
        link: "https://www.scimetic.com/",
        technologies: ["React JS", "Node JS", "AWS"],
      },
    ],
  },
};
