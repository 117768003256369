import React, { useState } from "react";
import { ReactComponent as ArrowIcon } from "../assets/common/svgs/Arrow.svg";
import { useNavigate } from "react-router-dom";
import { technologies } from "../technology/data";

const HireTechCard = ({ title, icon }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onClick={() => {
        const id =
          title == "ASP.Net"
            ? technologies.hasOwnProperty(title.toLowerCase().replace(".", ""))
            : technologies.hasOwnProperty(
                title.toLowerCase().replace(/\s/g, "")
              );
        if (id) {
          navigate(
            `/technology-details/id&${
              title == "ASP.Net"
                ? title.toLowerCase().replace(".", "")
                : title.toLowerCase().replace(/\s/g, "")
            }`
          );
        } else {
          navigate("/contact-us");
        }

        // navigate("/contact-us");
        // navigate("/client-hire", { state: { title: title } });
      }}
      className="flex h-[70px] w-[170px] cursor-pointer justify-between rounded-[10px] bg-white p-[10px] pl-[15px] max_sm:w-full max_sm:pr-[15px]"
    >
      <div className="flex flex-col text-[14px] font-medium leading-[21px] text-black-oak max_sm:flex-row max_sm:self-center">
        {title} <span className="max_sm:pl-[5px]">Developer</span>
      </div>
      <div className="h-fit max_sm:self-center">
        {isHovered ? (
          <div className=" h-[24px] w-[24px] rounded-full bg-[#F1F1F1]">
            <div className="BlueIcon flex h-full items-center justify-center">
              {/* <ArrowIcon className="w-[9px]" /> */}
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99981 3.3L1.34355 9.975C1.19355 10.125 1.0153 10.2 0.808805 10.2C0.602305 10.2 0.424304 10.125 0.274804 9.975C0.124804 9.825 0.0498047 9.64675 0.0498047 9.44025C0.0498047 9.23375 0.124804 9.05575 0.274804 8.90625L6.94981 2.25H1.2498C1.0373 2.25 0.859055 2.178 0.715055 2.034C0.571055 1.89 0.499305 1.712 0.499805 1.5C0.499805 1.2875 0.571805 1.10925 0.715805 0.965253C0.859805 0.821253 1.0378 0.749503 1.2498 0.750003H8.74981C8.96231 0.750003 9.14056 0.822003 9.28456 0.966003C9.42856 1.11 9.50031 1.288 9.49981 1.5V9C9.49981 9.2125 9.42781 9.39075 9.28381 9.53475C9.13981 9.67875 8.96181 9.7505 8.74981 9.75C8.53731 9.75 8.35906 9.678 8.21506 9.534C8.07106 9.39 7.99931 9.212 7.99981 9V3.3Z"
                  fill="#2275A1"
                />
              </svg>
            </div>
          </div>
        ) : (
          <img
            draggable="false"
            className={`${
              title === "php" ? "w-[30px]" : "w-[20px]"
            } min-w-[20px]`}
            src={icon}
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default HireTechCard;
