import React, { useState, Fragment } from "react";
import HomeBottomBG1 from "../../assets/common/images/HomeBG2.png";
import ServiceSectionComp from "../../Components/ServiceSectionComp";
import Layout from "../../Components/utils/Layout";
import TestingIMG from "../../assets/common/svgs/service/TestingIMG.svg";
import ToolIMG from "../../assets/common/images/ToolImg.png";
import Testimonial from "../../Components/pageComponent/Testimonial";
import ServiceCard from "../../Components/ServiceCard";
import DeviderLine from "../../Components/DeviderLine";

const ServiceTesting = () => {
  const [serviceData, setserviceData] = useState({
    DeviderTitle: "WHY IGEEK",
    Heading: "Why choose iGeek for Testing/QA services?",
    Image: TestingIMG,
    description:
      "We believe that exceptional software is the cornerstone of any successful digital endeavor. Our comprehensive Quality Assurance (QA) services are designed to ensure that your software is thoroughly tested, reliable, and efficient, delivering an outstanding user experience that sets you apart from the competition.",
    title: "Why choose iGeek for Testing/QA services?",

    cards: [
      {
        Image: ToolIMG,
        title: "Manual Testing",
        background: "bg-placebo-turquoise",
        description:
          "Our skilled QA analysts meticulously verify the functionality, usability, and compatibility of your software across various platforms and devices, ensuring a seamless and enjoyable experience for your users.",
      },
      {
        Image: ToolIMG,
        title: "Automated Testing",
        background: "bg-clear-skies",
        description:
          "Utilizing industry-leading tools and frameworks, our team develops and executes automated test suites to streamline the testing process, boost efficiency, and deliver consistent results.",
      },
      {
        Image: ToolIMG,
        title: "Performance Testing",
        background: "bg-placebo-turquoise",
        description:
          "We rigorously assess your software's performance under various conditions, identifying potential bottlenecks and optimizing your application for speed, stability, and scalability.",
      },
      {
        Image: ToolIMG,
        title: "Security Testing",
        background: "bg-clear-skies",
        description:
          "Our experts identify and address potential vulnerabilities within your software, safeguarding your data and protecting your users from malicious threats.",
      },
      {
        Image: ToolIMG,
        title: "Compliance Testing",
        background: "bg-placebo-turquoise",
        description:
          "We ensure that your software adheres to industry-specific standards and regulatory requirements, minimizing risks and fostering trust in your brand.",
      },
    ],
  });

  const [otherService, setotherService] = useState([
    {
      Image: ToolIMG,
      title: "Web Development",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-web-development",
    },
    {
      Image: ToolIMG,
      title: "UI/UX Design",
      background: "bg-clear-skies",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-ui-ux",
    },
    {
      Image: ToolIMG,
      title: "Product Development",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-product-development",
    },
    {
      Image: ToolIMG,
      title: "Dedicated Developer/Team",
      background: "bg-clear-skies",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-developer",
    },
    {
      Image: ToolIMG,
      title: "Mobile app Development",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-mobile-development",
    },
  ]);

  return (
    <>
      <Layout>
        <div className="">
          <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
            <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
              <img draggable="false" className="h-full object-cover" src={HomeBottomBG1} alt="" />
            </div>

            <div className="flex flex-col items-center justify-center text-center">
              <div className="HeadingText mt-[100px] max-w-[780px] max_md:px-[50px] max_sm:px-[20px]">
                Crafting Confidence and Quality beyond expectations.
              </div>
              <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
                Experience confidence in every click with our cutting-edge QA
                services. We blend rigorous testing, innovative strategies, and
                a passion for quality to deliver flawless software solutions.
              </div>
            </div>
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <ServiceSectionComp
              maxWidth={"max-w-[248px]"}
              serviceData={serviceData}
            />
          </div>

          {/* <div className="mt-[160px] max_md:mt-[80px]">
            <Testimonial />
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <div className="mt-[160px] max_md:mt-[80px]">
              <DeviderLine title="OTHERS" right="right-[75%]" />

              <div className="mt-[65px] flex flex-wrap justify-center gap-[15px] max_md:mt-[40px]">
                <div className="w-[403px] pt-[40px] text-[30px] font-bold leading-[45px] text-duskwood max_md:pt-[0px] max_md:pb-[40px] max_md:text-ExLargeSize max_md:leading-[32px] ">
                  <div className="max-w-[362px]">
                    We Provide Other Exclusive Services For Turn Your Business
                    Into A Brand
                  </div>
                </div>

                {otherService.map((cardData, index) => (
                  <Fragment key={index}>
                    <ServiceCard
                      title={cardData.title}
                      desc={cardData.description}
                      background={cardData.background}
                      readMore={cardData.link}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceTesting;
