import React, { useState } from "react";

const CollectingPersonalData = () => {
  const [SessionPersistent] = useState([
    {
      title: "Necessary / Essential Cookies",
      type: "Session Cookies",
      administered: "Us",
      purpose:
        "These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.",
    },
    {
      title: "Cookies Policy / Notice Acceptance Cookies",
      type: "Persistent Cookies",
      administered: "Us",
      purpose:
        "These Cookies identify if users have accepted the use of cookies on the Website.",
    },
    {
      title: "Functionality Cookies",
      type: "Persistent Cookies",
      administered: "Us",
      purpose:
        "These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.",
    },
  ]);

  const [PersonalData] = useState([
    "Email address",
    "First name and last name",
    "Phone number",
    "Usage Data",
  ]);

  const [TechnologiesCookies] = useState([
    {
      title: "Cookies or Browser Cookies.",
      description:
        " A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.",
    },
    {
      title: "Web Beacons.",
      description:
        " Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).",
    },
  ]);

  return (
    <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
      <div className="w-full">
        <div className="text-[26px] font-bold text-duskwood max_md:mt-[25px] max_md:text-[18px] max_md:leading-[32px]">
          Collecting and Using Your Personal Data
        </div>
        <div className="pt-[30px] text-[20px] font-semibold leading-[24px] text-bateau max_md:pt-[20px] max_md:text-[16px] max_md:leading-[20px] max_sm:pt-[10px]">
          Types of Data Collected
        </div>
        <div>
          <div className="pt-[30px] text-[20px] font-semibold leading-[24px] text-bateau max_md:pt-[20px] max_md:text-[16px] max_md:leading-[20px] max_sm:pt-[10px]">
            Personal Data
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </div>
          <div className="pl-[15px]">
            {PersonalData.map((itm, index) => (
              <div
                key={index}
                className="flex items-start gap-[10px] pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]"
              >
                <span className="mt-[12px] h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
                {itm}
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className="pt-[30px] text-[20px] font-semibold leading-[24px] text-bateau max_md:pt-[20px] max_md:text-[16px] max_md:leading-[20px] max_sm:pt-[10px]">
            Usage Data
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            Usage Data is collected automatically when using the Service.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </div>
        </div>

        <div>
          <div className="pt-[30px] text-[20px] font-semibold leading-[24px] text-bateau max_md:pt-[20px] max_md:text-[16px] max_md:leading-[20px] max_sm:pt-[10px]">
            Tracking Technologies and Cookies
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </div>
          <div className="pl-[15px]">
            {TechnologiesCookies.map((itm, index) => (
              <div
                key={index}
                className="flex items-start gap-[10px] pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]"
              >
                <span className="mt-[12px] h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
                <p>
                  <span className="font-semibold">{itm.title}</span>{" "}
                  {itm.description}
                </p>
              </div>
            ))}
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:text-SmallSize max_md:leading-[20px]">
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser. Learn more about cookies on the Free Privacy Policy
            website article.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:text-SmallSize max_md:leading-[20px]">
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </div>
          <div className="pl-[15px]">
            {SessionPersistent.map((itm, index) => (
              <div
                key={index}
                className="flex flex-col items-start pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]"
              >
                <div className="flex gap-[10px]">
                  <span className="mt-[12px] h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
                  <span className="font-semibold">{itm.title}</span>{" "}
                </div>
                <div className="pl-[15px]">
                  <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:text-SmallSize max_md:leading-[20px]">
                    Type: {itm.type}
                  </div>
                  <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:text-SmallSize max_md:leading-[20px]">
                    Administered by: {itm.administered}
                  </div>
                  <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:text-SmallSize max_md:leading-[20px]">
                    Purpose: {itm.purpose}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:text-SmallSize max_md:leading-[20px]">
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectingPersonalData;
